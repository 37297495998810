import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helper/axiosHelper";
import axios from "axios";

const initialState = {
  phone: "",
  //otp: "",
  party: "",
  accessToken: "",
  business: {},
  isLoggedIn: false,
  isOtpReceivedStatus: false,
  isOtpVerifiedStatus: false,
  errorStatus: false,
  errorMessage: {},
  otpErrorStatus: false,
  otpErrorMessage: {},
  tenantId: "",
  logo: [],
  businessname: [],
  businessaddres: [],
  isTenantFinding: false,
  isFindTenant: false,
  otpStatus: "",
  tenants:[],
  businessCity:"",
  businessPincode:"",
  businessState:""
};

export const fetchOtp = createAsyncThunk("auth/getOtp", async (data,{ rejectWithValue }) => {
  try {
    
    const result = data?.formData;
    
    const response = await axiosRequest({
      method: "POST",
      url: "auth/subscription-login",
      data: result,
      headers: {
        "Content-Type": "application/json",
        // "X-Tenant": data.tenantId?.tenant_id,

      },
    });
    //
    
    return response;
  } catch (error) {
    
    return rejectWithValue(error);
  }
});

export const otpVerify = createAsyncThunk("auth/otpVerify", async (data) => {
  // const token = process.env.REACT_APP_BEARER_TOKEN
  ////
  try {
    const result = {
      phone: data.phone,
      // tenant_id: data.tenantId,
      otp: data.otp,
      fcm_token: data.fcmToken
    };
    const response = await axiosRequest({
      method: "POST",
      url: "auth/subscription-verify",
      data: result,
      headers: {
        "Content-Type": "application/json",
        //  "X-Tenant": data.tenantId?.tenant_id,     
      },
    });

    //
    return response;
  } catch (error) {
    throw error;
  }
});

export const findTenant = createAsyncThunk(
  "auth/findTenant",
  async (domain) => {
    const token = process.env.REACT_APP_BEARER_TOKEN;
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await axios.get(`${apiUrl}/auth/find-tenant/${domain}`);
      
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addPhoneNumber: (state, action) => {
      state.phone = action.payload;
    },
    // addOtp: (state, action) => {
    //   state.otp = action.payload;
    // },
    setTenantId: (state, action) => {
      //
      state.tenantId = action.payload;
    },
    resetAuth: (state, action) => {
      //
      state.phone = "";
      state.otp = "";
      state.party = "";
      state.accessToken = "";
      state.business = {};
      state.isLoggedIn = false;
      state.isOtpReceivedStatus = false;
      state.isOtpVerifiedStatus = false;
      state.errorStatus = false;
      state.errorMessage = {};
      state.logo = [];
      state.businessname = [];
      state.businessaddres = [];
      state.isTenantFinding = false;
      state.isFindTenant = false
      state.tenants = [];
    },
    resetOtpError(state,action){
      state.isOtpVerifiedStatus = false;
      state.errorMessage = {};
      state.errorStatus = false;
      state.otpErrorStatus = false;
      state.otpErrorMessage = {};
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOtp.fulfilled, (state, action) => {
        state.isOtpReceivedStatus = true;
        state.errorStatus = false
        state.otpErrorStatus = false;
        state.otpStatus = "sent"
      })
      .addCase(fetchOtp.rejected, (state, action) => {
        
        state.errorStatus = true;
        state.errorMessage = action.payload;
        state.otpStatus = "error";
        state.isOtpReceivedStatus = false;
      })
      .addCase(otpVerify.fulfilled, (state, action) => {
        //
        state.party = action.payload.party;
        state.accessToken = action.payload?.token;
        // state.business = action.payload?.tenant_details;
        state.otpErrorStatus = false;
        state.otpStatus = "verified"

        state.isLoggedIn = true;
      })
      .addCase(otpVerify.rejected, (state, action) => {
        //
        state.otpErrorStatus = true;
        state.otpErrorMessage = action.payload;
      }).addCase(findTenant.pending, (state) => {
        state.isTenantFinding = true;
        state.otpStatus = "error";

      })
      .addCase(findTenant.fulfilled, (state, action) => {
        const tenantData = action.payload.tenantSData || [];
        state.logo = tenantData.find(item => item.key === 'logo')?.value || '';
        state.businessname = tenantData.find(item => item.key === 'business_name')?.value || 'Default Business Name';
        state.businessaddres = tenantData.find(item => item.key === 'business_address')?.value || 'Default Business Name';
        state.businessCity = tenantData.find(item => item.key === 'city')?.value || 'Default City Name';
        state.businessState = tenantData.find(item => item.key === 'state')?.value || 'Default City Name';
        state.businessPincode = tenantData.find(item => item.key === 'pincode')?.value || 'Default City Name';
        state.tenantId = action.payload;
        state.isFindTenant = true
        state.isTenantFinding = false;
        state.tenants =  action.payload.tenantSData || []
      })
      .addCase(findTenant.rejected, (state, action) => {
        state.errorStatus = false;
        state.isFindTenant = false;
        state.errorMessage = "Error while get find id. Please try again later.";
        state.isTenantFinding = false
      });
  },
});

export default authSlice.reducer;

export const { addPhoneNumber, resetAuth, setTenantId,resetOtpError } =
  authSlice.actions;
