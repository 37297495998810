import React, { useEffect, useRef, useState } from "react";
import Layout from "../../shared/components/Layout";
import Header from "../../shared/Header";
import "../css/invoice.css"
import InvoiceBody from "../components/InvoiceBody";
import { useLocation, useNavigate } from "react-router-dom";
import OrderDetails from "../components/OrderDetails";
import { axiosRequest } from "../../shared/helper/axiosHelper";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const Invoice = () => {
  const { state } = useLocation();
  const { accessToken, tenantId } = useSelector((state) => state.auth);
  const data = state.data;
  const party = state?.party;
  const payment = state?.payment;

  const pdfRef = useRef();
  const componentRef = useRef();


  const [isMobile, setIsMobile] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //  //
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const handlePrint = async () => {
    setLoading(true)
   
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `/invoice-data?pdf=true&template=1&invoice_id=${data?.voucher_id}&app_flavor=fitplusclub`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-Tenant": tenantId?.tenant_id,
        },
        responseType: "blob",
      });

      const pdfBlob = new Blob([response], { type: "application/pdf" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.setAttribute("download", `${data?.voucher_id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      const messageData = {
        token: accessToken,
        tenant_id: tenantId?.tenant_id,
        voucher_id: data?.voucher_id,
      };
      window.Flutter.postMessage(JSON.stringify(messageData));

    } catch (error) {
      setLoading(false)
      toast.error("Unable to download PDF. Please try again later.")
      //throw new Error("Error submitting subscription");
    }
  }

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };


  window.onPDFDownloadSuccess = function (message) {

    if (message == "success") {
      setLoading(false)
    } else if (message == "failed") {
      setLoading(false)
    } else {
      setLoading(false)
    }
  };

  return (
    <Layout>
      <ToastContainer />
      <Header
        title={"Manage Invoices"}
      />
      {!isMobile ? (
        <InvoiceBody
          pdfRef={componentRef}
          data={data}
          handleGoBack={handleGoBack}
          party={party}
          handlePrint={handlePrint}
        />
      ) : (
        <OrderDetails
          pdfRef={componentRef}
          data={data}
          handleGoBack={handleGoBack}
          party={party}
          handlePrint={handlePrint}
          loading={loading}
          payment={payment}
        />
      )}
    </Layout>
  );
};
export default Invoice;
