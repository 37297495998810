import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helper/axiosHelper";

const initialState = {
  getSubscriptionStatus: false,
  getSubscriptionData: [],
  getPaymentStatus: false,
  errorStatus: false,
  errorMessage: {},
  selectId: {},
  voucher: {},
  payment: [],
  attendance: [],
  loading: false
};

export const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (data) => {

    // const tenantId = process.env.REACT_APP_X_TENANT;
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscriptions/party-subscription/${data.partyId}`,
        headers: {

          "Content-Type": "application/json",
          Authorization: `Bearer ${data?.accessToken}`,
          "X-Tenant": data?.tenantId?.tenant_id,
        },
      });
      //

      return response.data;
    } catch (error) {
      throw error; // Re-throw the error to be handled by the Redux Toolkit
    }
  }
);

export const getSubscriptionVoucher = createAsyncThunk(
  "subscription/fetch",
  async (args) => {
    //

    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscription-vouchers/${args.partyId}`,
        headers: {
          Authorization: `Bearer ${args.accessToken}`,
          "X-Tenant": args.tenantId?.tenant_id,
        },
      });
      //
      return response.data;
    } catch (error) {
      //
      throw new Error("Error submitting subscription");
    }
  }
);

export const getAllSubscriptionVoucher = createAsyncThunk(
  "subscription/fetchAll",
  async (data) => {

    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscription-vouchers`,
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "X-Tenant": data.tenantId?.tenant_id,
        },
      });
      //
      return response.data;
    } catch (error) {
      throw new Error("Error submitting subscription");
    }
  }
);

export const getAllPaymentsWithPartyId = createAsyncThunk(
  "subscription/fetchPayment",
  async (data) => {
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `payment-party/${data.partyId}`,
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "X-Tenant": data.tenantId?.tenant_id,
        },
      });
      //
      return response;
    } catch (error) {
      console.error("Error fetching payments:", error);
      throw new Error("Error submitting subscription");
    }
  }
);


export const getSubscriptionAttendance = createAsyncThunk(
  "subscription/attendance",
  async (data) => {

    try {
      const response = await axiosRequest({
        method: "GET",
        url: `/subscriptions/attendance/${data?.id}`,
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "X-Tenant": data.tenant_id?.tenant_id,
        },
      });
      //
      return response.data;
    } catch (error) {
      throw new Error("Error submitting subscription");
    }
  }
);

export const pdfDownload = createAsyncThunk(
  "subscription/pdf",
  async (data) => {

    try {
      const response = await axiosRequest({
        method: "GET",
        url: `/invoice-data?pdf=true&invoice_id=${data?.id}&app_flavor=fitplusclub`,
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "X-Tenant": data.tenant_id?.tenant_id,
        },
      });
      //
      return response.data;
    } catch (error) {
      throw new Error("Error submitting subscription");
    }
  }
);


const subscriptionSilce = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetSubscription: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSubscription.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.loading = false
        state.getSubscriptionStatus = true;
        state.getSubscriptionData = action.payload;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.loading = false
        state.errorStatus = false;
        state.errorMessage =
          "Error while get subscription by party. Please try again later.";
      })
      .addCase(getSubscriptionVoucher.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getSubscriptionVoucher.fulfilled, (state, action) => {
        state.loading = false
        state.getSubscriptionStatus = true;
        state.selectId = action.payload;
      })
      .addCase(getSubscriptionVoucher.rejected, (state, action) => {
        state.loading = false
        state.errorStatus = false;
        state.errorMessage =
          "Error while get subscription by party. Please try again later.";
      })
      .addCase(getAllSubscriptionVoucher.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAllSubscriptionVoucher.fulfilled, (state, action) => {
        state.loading = false
        state.getSubscriptionStatus = true;
        state.voucher = action.payload;
      })
      .addCase(getAllSubscriptionVoucher.rejected, (state, action) => {
        state.loading = false
        state.errorStatus = false;
        state.errorMessage =
          "Error while get subscription by party. Please try again later.";
      })
      .addCase(getAllPaymentsWithPartyId.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAllPaymentsWithPartyId.fulfilled, (state, action) => {
        state.loading = false
        state.getPaymentStatus = true;
        state.payment = action.payload;
      })

      .addCase(getAllPaymentsWithPartyId.rejected, (state, action) => {
        state.loading = false
        state.errorStatus = false;
        state.errorMessage = "Error while get payment by party. Please try again later.";
      })
      .addCase(getSubscriptionAttendance.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getSubscriptionAttendance.fulfilled, (state, action) => {
        state.loading = false
        state.attendance = action.payload;
      })
      .addCase(getSubscriptionAttendance.rejected, (state, action) => {
        state.loading = false
        state.errorStatus = false;
        state.errorMessage = "Error while get attendance by subscription. Please try again later.";
      })
  },
});

export const { resetSubscription } = subscriptionSilce.actions;

export default subscriptionSilce.reducer;
