import { useSelector } from "react-redux";



const GlobalCurrency = ({ price }) => {
    const currency = useSelector((state) => state.getSetting?.dataSetting.currency);

    const formattedPrice = price 
        ? parseFloat(price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        : "0.00";

    return (
        <>
             {currency ? currency : "₹"} {formattedPrice}
        </>
    )
}

export default GlobalCurrency;