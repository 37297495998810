import { useEffect, useRef, useState } from "react";
import { Button, Card, Container, Image } from "react-bootstrap"
import QRCode from "react-qr-code";
import logo from '../../../assets/images/fitPlusLogo.png'
import { useSelector } from "react-redux";
import { placeOfSupplyList } from "../../shared/components/PlaceOfSupply";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { UilPrint, UilImport, UilUser } from "@iconscout/react-unicons";

const MembershipCard = ({ data }) => {
    

    const { businessname, businessaddres, businessCity, businessState, businessPincode } = useSelector((state) => state.auth);
    //
    const [qrCodeItemData, setQrCodeItemData] = useState(null);
    const componentRef = useRef();

    useEffect(() => {
        const qrCodeData = {
            member_id: data?.member_id,
            subscription_id: data?.id,
        };
        setQrCodeItemData(JSON.stringify(qrCodeData));
    }, [data]);

    const state = placeOfSupplyList.find((value) => value.value == businessState);
    const DownloadPdf = () => {
        if (!componentRef.current) {
          console.error("Component reference is not set.");
          return;
        }
      
        html2canvas(componentRef.current, {
          scale: 2,
          useCORS: true,
          allowTaint: true,
          logging: true,
        }).then((canvas) => {
          // Convert canvas to base64 image URL
          const imageData = canvas.toDataURL("image/png");
      
          // Create a download link
          const link = document.createElement("a");
          link.href = imageData;
          link.download = `memberCard_${Date.now()}.png`;
      
          // Trigger download
          link.click();
        });
      };


    return (
        <>
            <Container className="" fluid>
                <div className="d-flex mt-10  justify-content-center align-items-center" ref={componentRef}>
                    <Card className="shadow-sm border" style={{ width: "22rem" }}>
                        {/* <Card.Header className="d-flex justify-content-center align-items-center py-2" style={{ borderBottom: '1px solid lightgray' }}>
                            <Card.Title >Membership Card</Card.Title>
                        </Card.Header> */}
                        <Card.Body className="py-3">
                            <div className="d-flex justify-content-center align-items-center">
                                <div style={{ border: '1px solid gray', height: '10px', width: '50px', borderRadius: '10px' }}>

                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                <Image src={logo} height={50} width={80} alt="Your Logo" />
                            </div>

                            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                                <h5>{data?.subscription_number}</h5>
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                <QRCode
                                    value={qrCodeItemData || ""}
                                    size={130}
                                    level={"H"}
                                    includeMargin={true}
                                />
                            </div>
                            <hr />
                            <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
                                <h4>{businessname}</h4>
                                <text style={{ fontSize: '14px' }}>{businessaddres}</text>
                                <text style={{ fontSize: '14px' }}>{businessCity},{state.label} - {businessPincode}</text>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <Button variant="outline-primary" className="py-1" onClick={DownloadPdf}>
                        <UilImport className="mx-2 mb-1" size="16" />
                        Download
                    </Button>
                    {/* <Button variant="outline-secondary mx-4" className="py-1" onClick={handlePrint}>Print</Button> */}
                </div>
            </Container>
        </>
    )
}

export default MembershipCard;  