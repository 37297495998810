import { Col, Row } from "react-bootstrap";
import PaymentModal from "./PaymentModal";
import { useState } from "react";
import { getLocalizedDate } from "../../shared/helper/dateUtils";
import GlobalCurrency from "../../shared/helper/GlobalCurrency";


const PaymentDetails = ({ payment }) => {
    const [show, setShow] = useState(false);
    const [paymentData, setPaymentData] = useState({});


    const handleShow = (row) => {

        setPaymentData(row);
        setShow(true);
    }
    return (
        <>
            <Row className="px-3">
                <Col lg={12} className="py-2 px-0 d-flex justify-content-between align-items-center" style={{
                    borderTop: "1px solid lightgray",
                    borderBottom: "1px solid lightgray",
                }}>
                    <text style={{ fontWeight: 600 }} className="">Payments</text>
                </Col>

                <Col lg={12} className="py-2 px-0" style={{
                    height: 'calc(20vh - 50px)',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}>
                    {payment && payment?.length > 0 ? [...payment]
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                        .map((data, index) => {
                            return (

                                <div className="cursor-pointer" key={index} onClick={() => handleShow(data)} >
                                    <div className="d-flex justify-content-between ">
                                        <text style={{ fontSize: '13px' }}># {data?.payment?.payment_number}</text>
                                        <text style={{ fontSize: '13px' }}>{getLocalizedDate(data?.created_at)}</text>
                                    </div>
                                    <div className="d-flex align-items-center  justify-content-between mt-2">
                                        <div className="d-flex flex-column">
                                            <div>
                                                <GlobalCurrency price={data?.payment?.amount || 0} />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <div className="py-0 px-3" style={{
                                                backgroundColor:
                                                    data?.payment?.mode === "card"
                                                        ? "#FFE0B2"
                                                        : data?.payment?.mode === "upi"
                                                            ? "#CCE5FF"
                                                            : data?.payment?.mode === "cash"
                                                                ? "#D4EDDA"
                                                                : "transparent",
                                                borderRadius: "5px",
                                                color: 'black',
                                                fontWeight: 700,
                                                fontSize: "13px",
                                            }}> {data?.payment?.mode
                                                ? data.payment.mode.charAt(0).toUpperCase() + data.payment.mode.slice(1)
                                                : ""}</div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>


                            )
                        }) : <div className="d-flex justify-content-center mt-5"><text>No payments yet</text></div>}
                </Col>
            </Row>
            {show && <PaymentModal show={show} setShow={setShow} paymentData={paymentData} />}
        </>
    )
}

export default PaymentDetails;