import { Table } from "react-bootstrap";
import GlobalCurrency from "../../../shared/helper/GlobalCurrency";

const TablePortion = ({ data }) => {
    
    return (
        <>
            <Table responsive>
                <thead>
                    <tr
                        className="px-2 "
                        style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            color: "#4682B4",
                            // borderTop: "1px solid lightgray",
                            textAlign: "center",
                        }}
                    >
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            S.NO
                        </th>
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                                width: "100px",
                            }}
                        >
                            ITEMS
                        </th>
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            Code
                        </th>
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            QTY
                        </th>
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            RATE
                        </th>
                        {data?.voucher?.discount_amount > 0 &&
                            <th
                                style={{
                                    color: "#4682B4",
                                    borderRight: "1px solid lightgray",
                                }}
                            >
                                Discount
                            </th>}
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            TAX
                        </th>
                        <th style={{ color: "#4682B4" }}>AMOUNT</th>
                    </tr>
                </thead>
                {data &&
                    data?.voucher?.voucher_items?.length > 0 &&
                    data?.voucher?.voucher_items?.map((item, index) => {

                        return (
                            <tbody>
                                <tr
                                    style={{
                                        fontSize: "13px",
                                        fontWeight: 400,
                                        textAlign: "center",
                                    }}
                                >
                                    <td
                                        style={{
                                            borderBottom: "none",
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        {" "}
                                        {index + 1}
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "none",
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        {" "}
                                        {item?.product_name}
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "none",
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        {" "}
                                        {item?.product_code}
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "none",
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        {" "}
                                        {item?.quantity}
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: "none",
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >

                                        <GlobalCurrency price={item?.item_price || 0} />
                                    </td>
                                    {item?.item_discount_amount > 0 && <td
                                        style={{
                                            borderBottom: "none",
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >

                                        <GlobalCurrency price={item?.item_discount_amount || 0} />
                                    </td>}
                                    <td
                                        style={{
                                            borderBottom: "none",
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >

                                        <GlobalCurrency price={item?.tax_total || 0} />
                                        <span style={{ color: "#808080" }}>

                                        </span>
                                    </td>
                                    <td style={{ borderBottom: "none" }}>
                                        <GlobalCurrency price={item?.net_total || 0} />
                                    </td>
                                </tr>


                            </tbody>
                        )
                    })}

                <tfoot>
                    {[...Array(20)].map((_, rowIndex) => (<tr>
                        <td key={rowIndex}
                            style={{
                                borderBottom: "none",
                                borderRight: "1px solid lightgray",
                            }}
                        ></td>
                        <td
                            style={{
                                borderBottom: "none",
                                borderRight: "1px solid lightgray",
                            }}
                        ></td>
                        <td
                            style={{
                                borderBottom: "none",
                                borderRight: "1px solid lightgray",
                            }}
                        ></td>
                        <td
                            style={{
                                borderBottom: "none",
                                borderRight: "1px solid lightgray",
                            }}
                        ></td>
                        <td
                            style={{
                                borderBottom: "none",
                                borderRight: "1px solid lightgray",
                            }}
                        ></td>
                        {data?.voucher?.discount_amount > 0 && <td
                            style={{
                                borderBottom: "none",
                                borderRight: "1px solid lightgray",
                            }}
                        ></td>}
                        <td
                            style={{
                                borderBottom: "none",
                                borderRight: "1px solid lightgray",
                            }}
                        ></td>
                        <td style={{ borderBottom: "none" }}></td>
                    </tr>))}
                    <tr
                        style={{
                            fontSize: "13px",
                            textAlign: "right",

                            fontWeight: 500,
                        }}
                    >
                        <td
                            style={{
                                borderRight: "1px solid lightgray",
                                backgroundColor: "rgb(248, 249, 250)",
                                borderTop: "1px solid lightgray",
                            }}
                        ></td>
                        <td
                            style={{
                                borderRight: "1px solid lightgray",
                                backgroundColor: "rgb(248, 249, 250)",
                                borderTop: "1px solid lightgray",
                            }}
                        >
                            Total
                        </td>
                        <td
                            style={{
                                borderRight: "1px solid lightgray",
                                backgroundColor: "rgb(248, 249, 250)",
                                borderTop: "1px solid lightgray",
                            }}
                        ></td>
                        <td
                            style={{
                                borderRight: "1px solid lightgray",
                                backgroundColor: "rgb(248, 249, 250)",
                                borderTop: "1px solid lightgray",
                            }}
                        >

                        </td>
                        <td
                            style={{
                                borderRight: "1px solid lightgray",
                                backgroundColor: "rgb(248, 249, 250)",
                                borderTop: "1px solid lightgray",
                            }}
                        >

                        </td>
                        {data?.voucher?.discount_amount > 0 && <td
                            style={{
                                textAlign: "center",
                                borderRight: "1px solid lightgray",
                                backgroundColor: "rgb(248, 249, 250)",
                                borderTop: "1px solid lightgray",
                            }}
                        ><GlobalCurrency price={data?.voucher?.discount_amount || 0} /></td>}
                        <td
                            style={{
                                borderRight: "1px solid lightgray",
                                textAlign: "center",
                                backgroundColor: "rgb(248, 249, 250)",
                                borderTop: "1px solid lightgray",
                            }}
                        >
                            <GlobalCurrency price={data?.voucher?.tax_total || 0} />
                        </td>
                        <td
                            align="center"
                            style={{
                                backgroundColor: "rgb(248, 249, 250)",
                                borderTop: "1px solid lightgray",
                            }}
                        >
                            <GlobalCurrency price={data?.voucher?.grand_total || 0} />
                        </td>
                    </tr>
                    <tr
                        style={{
                            fontSize: "13px",
                            textAlign: "right",
                            fontWeight: 500,
                        }}
                    >
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        >
                            Received Amount
                        </td>
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        {data?.voucher?.discount_amount > 0 && <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>}
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        <td align="center">

                            <GlobalCurrency price={data?.voucher?.paid_amount || 0} />
                        </td>
                    </tr>
                    <tr
                        style={{
                            fontSize: "13px",
                            textAlign: "right",
                            fontWeight: 500,
                            borderBottom: '1px solid lightgray'
                        }}
                    >
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        >
                            Balance Amount
                        </td>
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        {data?.voucher?.discount_amount > 0 && <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>}
                        <td
                            style={{ borderRight: "1px solid lightgray" }}
                        ></td>
                        <td align="center">

                            <GlobalCurrency price={data?.voucher?.grand_total - Number(data?.voucher?.paid_amount)
                                ? data?.voucher?.grand_total -
                                Number(data?.voucher?.paid_amount)
                                : 0} />
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </>
    )
}

export default TablePortion;