import React from 'react'
import { faList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavDropdown, Navbar, Nav, Card, ListGroup } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import LogoutModal from "./Logout";
import {
  UilHome,
  UilBell,
  UilInvoice,
  UilCreditCard,
  UilListUl,
  UilSignOutAlt,
  UilBars
} from "@iconscout/react-unicons";
import { resetAuth } from "../../login/store/authSlice";
import { resetSubscription, } from "../../subscription/store/subscriptionSlice";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { UilUser, UilSync } from '@iconscout/react-unicons'
import { useSwipeable } from 'react-swipeable'


const DropDownNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const isActive = (path) => path == location.pathname;
  const { party } = useSelector((state) => state.auth);
  console.log("party", party)

  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false)

  const handleNavigate = (navigation) => {
    navigate(`/${navigation}`)
  }

  const handleLogoutModal = () => {
    setShow(!show);
  };

  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetSubscription());
    navigate("/login");
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handlers = useSwipeable({
    onSwipedRight: () => setIsOpen(true),  
    onSwipedLeft: () => setIsOpen(false),  
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (

    <div {...handlers}>
      <UilBars className="mt-2" onClick={toggleDrawer}
        style={{ cursor: "pointer", color: 'white' }}
        size="18"
      />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='left'
        className='bla bla bla'
      >
        <div style={{ height: '80px', background: '#1A237E' }}>
          <div className='d-flex justify-content-start align-items-center p-5'>
            <div className='d-flex justify-content-center align-items-center' style={{
              height: "40px",
              width: "40px",
              borderRadius: "30px",
              cursor: "pointer",
              border: '2px solid white'
            }}>
              {party?.name ? (
                <span style={{ fontSize: "25px", fontWeight: "bold", color: "white" }}>
                  {party.name.includes(" ")
                    ? party.name
                      .split(" ")
                      .slice(0, 2)
                      .map((word) => word[0].toUpperCase())
                      .join("")
                    : party.name[0].toUpperCase()}
                </span>
              ) : (
                <UilUser size="18" color="white" />
              )}
            </div>
            <div className="d-flex justify-content-center mx-4">
              <text style={{ fontSize: '16px', color: 'white', fontWeight: 600 }}>{party?.name}</text>
            </div>
          </div>
        </div>
        <div >
          <Card>
            <Card.Body className='px-2 py-2'>
              <ListGroup className="custom-list ">
                <ListGroup.Item 
                  action
                  onClick={() => handleNavigate("home")}
                  className={`custom-list-item ${location.pathname === "/home" ? "active py-3" : ""} `}>
                  <UilHome size="18" className="me-3" /> Home
                </ListGroup.Item>

                <ListGroup.Item
                  action
                  onClick={() => handleNavigate("subscription")}
                  className={`custom-list-item  ${location.pathname === "/subscription" ? "active py-3" : ""}`}>
                  <UilBell size="18" className="me-3" /> Memberships
                </ListGroup.Item>

                <ListGroup.Item
                  action
                  onClick={() => handleNavigate("subscription/invoices")}
                  className={`custom-list-item ${location.pathname === "/subscription/invoices" ? "active py-3" : ""}`}>
                  <UilInvoice size="18" className="me-3" /> Invoices
                </ListGroup.Item>

                <ListGroup.Item
                  action
                  onClick={handleLogoutModal}
                  className="custom-list-item logout-item">
                  <UilSignOutAlt size="18" className="me-3" color="red" /> Logout
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

        </div>
      </Drawer>
      {show && <LogoutModal onConfirm={handleLogout} onCancel={setShow} />}
    </div>
  )

}
export default DropDownNavigation