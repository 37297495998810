import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { findTenant, setTenantId } from "./modules/login/store/authSlice";
import axios from "axios";
import "./App.css";
import "./assets/css/custom.css";
import "./assets/css/style.css";
import "./assets/css/frontend.css";
import Subscription from "./modules/subscription/Subscription";
import HomePage from "./modules/home/HomePage";
import NoPage from "./modules/shared/components/NoPage";
import LoginScreen from "./modules/login/LoginScreen";
import { useSelector } from "react-redux";
import SubscriptionDetails from "./modules/subscription/SubscriptionDetails";
import InvoicesIndex from "./modules/invoice/screens/InvoicesList";
import PaymentIndex from "./modules/payment/PaymentIndex";
import Statements from "./modules/statement/Statement";
import MultiTenants from "./modules/login/MultiTenants";
import NotificationModal from "./modules/shared/components/NotificationModal";
import ProtectedRoute from "./ProtectedRoute";
import Invoice from "./modules/invoice/screens/Invoice";


function App() {

  const [showNotification, setShowNotification] = useState(false);
  const [notificaion, setNotificatoion] = useState({})
  const { accessToken, isLoggedIn, isFindTenant } = useSelector((state) => state.auth)
  


  const dispatch = useDispatch();

  // const queryParameters = new URLSearchParams(window.location.search);
  // const domain = queryParameters.get("domain");
  // //
  // //

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // //
  //const tenantId = useSelector(state=>state.auth.tenantId)
  //

  const apiUrl = process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch(findTenant(domain));
  //   };

  //   fetchData();
  // }, [apiUrl, domain]);

  window.receiveMessage = function (message) {
    //
    //
    if (typeof message === 'object') {
      var sub_id = message['id']
      //
      //
    }
    if (isLoggedIn) {
      setNotificatoion(message)
      setShowNotification(true)
    }


  };
  const handleCloseNotification = () => {
    setShowNotification(false)
    setNotificatoion({})
  }




  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Redirect to home or login based on authentication */}
          <Route
            path="/"
            element={
              <Navigate replace to={isLoggedIn && accessToken && isFindTenant ? "home" : "login"} />
            }
          />

          {/* Public Route: Login Page */}
          <Route path="login" element={<LoginScreen isMobile={isMobile} />} />

          {/* Protected Routes */}
          <Route path="home" element={<ProtectedRoute element={<HomePage isMobile={isMobile} />} isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
          <Route path="subscription" element={<ProtectedRoute element={<Subscription isMobile={isMobile} />} isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
          <Route path="multiBusiness" element={<ProtectedRoute element={<MultiTenants isMobile={isMobile} />} isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
          <Route path="subscription/details" element={<ProtectedRoute element={<SubscriptionDetails isMobile={isMobile} />} isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
          <Route path="subscription/invoices" element={<ProtectedRoute element={<InvoicesIndex isMobile={isMobile} />} isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
          <Route path="subscription/invoice" element={<ProtectedRoute element={<Invoice isMobile={isMobile} />} isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
          <Route path="subscription/payment" element={<ProtectedRoute element={<PaymentIndex isMobile={isMobile} />} isLoggedIn={isLoggedIn} accessToken={accessToken} />} />
          <Route path="subscription/statement" element={<ProtectedRoute element={<Statements isMobile={isMobile} />} isLoggedIn={isLoggedIn} accessToken={accessToken} />} />

          {/* 404 Page */}
          <Route path="*" element={<NoPage />} />
        </Routes>

        {/* Notification Modal */}
        {showNotification && (
          <NotificationModal
            show={showNotification}
            handleCloseNotification={handleCloseNotification}
            notification={notificaion}
          />
        )}
      </BrowserRouter>



    </>
  );
}

export default App;
