import React, { useEffect, useRef, useState } from "react";
//import bill from "../../assets/images/logo6-small.png";
import appLogo from "../../assets/images/fitPlusLogo.png"
import adolLogo from "../../assets/images/poweredby--adol-logo.png"
import {
  Row,
  Col,
  Button,
  Image,
  InputGroup,
  FormControl,
  Container,
  Form,
  Card,

} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addPhoneNumber, fetchOtp, otpVerify, resetAuth, resetOtpError } from "./store/authSlice";
import { useNavigate } from "react-router-dom";
import { findTenant } from "./store/authSlice";
import LoadingSpinner from "../shared/helper/LoadingSpinner";
import { toast, ToastContainer } from "react-toastify";



const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { isOtpReceivedStatus, accessToken, isLoggedIn, tenantId, errorStatus, errorMessage, otpErrorStatus, otpStatus, otpErrorMessage, logo, businessname, businessaddres, party, isFindTenant } = useSelector((state) => state.auth)
  const [getOtp, setGetOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [formData, setFormData] = useState({ phone: "" });
  const [loading, setLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const [timer, setTimer] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [fcmToken, setFcmToken] = useState("");
  // const [getOtp, setGetOtp] = useState(false);



  const otpInputRef = useRef(null);

  useEffect(() => {
    dispatch(resetAuth())
  }, [])

  useEffect(() => {
    if (window.FlutterChannel) {
      window.FlutterChannel.postMessage("requestFCMToken");
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      //
      const associateTenants = JSON.parse(party.associate_tenants)

      if (associateTenants.length == 1) {

        dispatch(findTenant(associateTenants[0].business_id));
        // navigate('/home')
      } else {

        navigate('/multiBusiness')
      }

      //
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isFindTenant) {

      navigate('/home')
    }

  }, [isFindTenant])



  // Receive the FCM token from Flutter
  window.receiveFCMToken = function (token) {
    setFcmToken(token); // Store the FCM token in state
  };



  const handlePhoneInputChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ""); // Replace any non-digit character with an empty string
    setFormData({ ...formData, phone: inputValue });
    setPhoneError("");
  };

  useEffect(() => {
    if (otpErrorStatus) {
      toast.error("OTP verification failed. please try again later")
      setGetOtp(false);
      setVerify(false);
      setCanResend(false);
      setTimer(0);
      setCanResend(true);
      setLoading(false)
      setOtp("")
      dispatch(resetOtpError());

    }
  }, [otpErrorStatus])


  useEffect(() => {
    if (errorStatus) {
      toast.error(errorMessage)
      setGetOtp(false);
      setVerify(false);
      setCanResend(false);
      setTimer(0);
      setCanResend(true);
      dispatch(resetOtpError());
    }
  }, [errorStatus])

  const handleGetOtp = () => {
    if (!formData.phone || formData.phone.length < 10) {
      setPhoneError("Please enter the phone number");
      return;
    }
    setTimer(30);
    setGetOtp(true);
    setVerify(true);
    setCanResend(false);
    if (otpInputRef.current) {
      otpInputRef.current.focus();
    }
    dispatch(fetchOtp({ formData }))
  };

  useEffect(() => {
    if (otpStatus == "sent" && timer) {
      toast.success("OTP sent successfully")
      setTimer(30);
      setCanResend(false);
    } else if (otpStatus == "verified" && timer) {
      toast.success("OTP verified successfully")
      setTimer(0);
      setCanResend(false);
    } else if (otpStatus == "error") {
      setTimer(0);
      setCanResend(true);
    }
  }, [otpStatus]);

  useEffect(() => {

    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else if (timer === 0 && getOtp) {
      setCanResend(true);

    }


  }, [timer, getOtp]);

  const handleVerify = () => {
    if (!otp || otp.length < 6) {
      setOtpError("Please enter OTP");
    } else {
      setLoading(true);
      setVerify(false);
      dispatch(otpVerify({ phone: formData.phone, otp: otp, fcmToken: fcmToken }))
    }
  };

  const handleOtpChange = (e) => {

    setOtp(e.target.value);
    // setOtpError("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleGetOtp();
    }
  };

  const handleVerifyKeyPress = (event) => {
    if (otp.length > 5 && event.key === "Enter") {
      handleVerify();
    }
  };

  const handleKeyDown = (e) => {

    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }

    if (otp.length > 5 && e.key === 'Enter') {
      handleVerify();
    }
  };


  return (

    <>
      <ToastContainer />
      <Container fluid className="bg-white"
        style={{
          height: "calc(100vh - 10px)",
          overflowX: "hidden",
          overflowY: "auto",
          backgroundRepeat: "repeat",
          backgroundSize: "auto",
        }}>
        <div className="d-flex justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 200px)' }}>

          <Card className="w-100 w-md-75 w-lg-50 border"
            style={{
              maxWidth: '450px',
              //boxShadow: "1px 1px 2px hsl(0deg 0% 0% / 0.38)",
            }}>
            <Card.Header>
              <Col className="d-flex justify-content-center">
                <Image src={appLogo} alt="FitplusClub+" style={{ height: "75px" }} />
              </Col>
            </Card.Header>
            <Card.Body className="">
              <Row>
                <Col xs={12}>
                  <Form.Label className="d-block " style={{ fontSize: '14px' }}>
                    Enter your mobile number
                  </Form.Label>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col xs={12} className="">
                  <InputGroup className="mb-3 w-100">
                    <InputGroup.Text id="phone-prefix">+91</InputGroup.Text>
                    <FormControl
                      type="text"
                      name="phone"
                      pattern="[0-9]*"
                      maxLength={10}
                      placeholder="Please enter your mobile number"
                      onChange={handlePhoneInputChange}
                      onKeyPress={handleKeyPress}
                      value={formData.phone}
                      autoFocus={true}
                    />
                  </InputGroup>
                </Col>
                {phoneError && <div className="text-danger text-center">{phoneError}</div>}
              </Row>

              {!getOtp && (
                <Row className="mt-3">
                  <Col xs={12} className="">
                    <Button className="" onClick={handleGetOtp}>
                      Get OTP
                    </Button>
                  </Col>
                </Row>
              )}

              {getOtp && (
                <Row className="mt-3">
                  <Col xs={12}>
                    <Form.Label className="d-block " style={{ fontSize: '14px' }}>
                      Enter OTP
                    </Form.Label>
                    <FormControl
                      type="text"
                      name="otp"
                      pattern="[0-9]*"
                      minLength={6}
                      maxLength={6}
                      ref={otpInputRef}
                      value={otp}
                      onChange={(e) => handleOtpChange(e)}
                      autoFocus={true}
                      onKeyDown={handleKeyDown}
                      onKeyPress={handleVerifyKeyPress}
                      className=""
                    />
                    {otpError && <div className="text-danger ">{otpError}</div>}
                  </Col>
                </Row>
              )}

              {verify && (
                <Row className="mt-3">
                  <Col xs={12} className="d-flex align-items-center">
                    <Button className="" onClick={handleVerify}>
                      Verify
                    </Button>
                    {canResend && (
                      <Button variant="link" onClick={handleGetOtp} className="mt-2">
                        Resend OTP
                      </Button>
                    )}
                    {!canResend && (
                      <Form.Label className="text-success mt-2 mx-2">
                        OTP sent : {timer} Sec
                      </Form.Label>
                    )}
                  </Col>
                </Row>
              )}
              {loading && <LoadingSpinner />}
            </Card.Body>
          </Card>
        </div>

        <Row className="d-flex justify-content-center align-items-center text-center">
          <Col>
            <div style={{
              fontSize: '12px',
              padding: '80px'
            }}>
              {/* <div>Powered By</div> */}
              <div>
                <Image src={adolLogo} alt="Bill Now" style={{ height: "50px" }} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    </>

  );
};

export default LoginScreen;
