import { useEffect, useMemo, useState } from "react";
import Header from "../shared/Header";
import Layout from "../shared/components/Layout";
import { Row, Col, Container, Tabs, Tab, Card, FormControl, InputGroup, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllPaymentsWithPartyId, getAllSubscriptionVoucher, getSubscription } from "./store/subscriptionSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { UilSearch, UilAngleDown, UilSync } from '@iconscout/react-unicons'
import GlobalCurrency from "../shared/helper/GlobalCurrency";
import LoadingSpinner from "../shared/helper/LoadingSpinner";
import MembshipListCard from "./components/MembershipListCard";
import SearchComponent from "../shared/components/SearchComponent";

const Subscription = ({ isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state;

  const { getSubscriptionData, loading } = useSelector((state) => state.subscription);
  const { party, accessToken, tenantId } = useSelector((state) => state.auth);

  const [activeTab, setActiveTab] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdownTab, setDropdownTab] = useState("inactive");


  useEffect(() => {
    if (state == "active") {
      setActiveTab("active")
    } else {
      setActiveTab("all")
    }

  }, [])


  useEffect(() => {
    let partyId = party?.party_id;
    if (partyId && accessToken && tenantId) {
      dispatch(getSubscription({ partyId, accessToken, tenantId }));
    }
  }, [party, accessToken, tenantId]);


  useEffect(() => {
    if (getSubscriptionData) {
      setFilteredData(getSubscriptionData);
    }
  }, []);

  const allCount = getSubscriptionData?.length || 0;
  const activeCount = getSubscriptionData?.filter((data) => data.status === "active").length || 0;
  const inactiveCount = getSubscriptionData?.filter((data) => data.status === "inactive").length || 0;
  const pendingCount = getSubscriptionData?.filter((data) => data.status === "pending").length || 0;
  const cancelledCount = getSubscriptionData?.filter((data) => data.status === "cancelled").length || 0;

  const finalFilteredData = useMemo(() => {
    if (!getSubscriptionData) return [];

    let filtered = [...getSubscriptionData];

    if (activeTab !== "all") {
      filtered = filtered.filter((data) => {
        if (activeTab === "active") return data.status === "active";
        if (dropdownTab === "inactive") return data.status === "inactive";
        if (dropdownTab === "pending") return data.status === "pending";
        if (dropdownTab === "cancelled") return data.status === "cancelled";
        return false;
      });
    }

    if (searchQuery) {
      filtered = filtered.filter((data) =>
        data?.subscription_number?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return filtered;
  }, [activeTab, getSubscriptionData, searchQuery, dropdownTab]);


  const getStatusColor = (status) => {
    const colors = {
      active: { bg: "#D4EDDA", text: "#155724" },
      pending: { bg: "#D9EFFF", text: "#0D47A1" },
      inactive: { bg: "#F8D7DA", text: "#721C24" },
      cancelled: { bg: "#F8D7DA", text: "#721C24" },
    };
    return colors[status] || { bg: "#E0E0E0", text: "#000" };
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const getDropdownLabel = () => {
    switch (dropdownTab) {
      case "inactive":
        return `Inactive (${inactiveCount})`;
      case "pending":
        return `Pending (${pendingCount})`;
      case "cancelled":
        return `Cancelled (${cancelledCount})`;
      default:
        return "Select Status";
    }
  };

  const handleRowClick = (data) => {
    navigate("/subscription/details", { state: data });
  };


  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };


  const handleRefresh = () => {
    const partyId = party?.party_id
    dispatch(getSubscription({ partyId, accessToken, tenantId }))
    dispatch(getAllSubscriptionVoucher({ partyId, accessToken, tenantId }));
    dispatch(getAllPaymentsWithPartyId({ partyId, accessToken, tenantId }));
  }

  return (
    <Layout>
      <Header title="Memberships" ItemAction={<UilSync className={!isMobile ? 'mx-3 mt-2' : 'mt-2'} onClick={handleRefresh} size="20" color={isMobile ? "white" : 'gray'} />} />
      <Container
        className="px-2 py-2"
        fluid
      // style={{
      //   height: "calc(100vh - 80px)",
      //   overflowY: 'hidden',
      //   overflowX: "hidden",
      //   backgroundColor: '#ECEFF1'
      // }}

      >
        <div className=" py-3" style={{ backgroundColor: 'white', height: "calc(100vh - 80px)", borderRadius: '5px' }}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Tabs
                activeKey={activeTab}
                onSelect={(key) => {
                  setActiveTab(key)
                }}
                id="subscription-tabs"
                className="mb-5"
                variant="underline"
              //style={{ borderBottom: "1px solid lightgray" }}
              >
                <Tab eventKey="all" title={`All (${allCount})`} />
                <Tab eventKey="active" title={`Active (${activeCount})`} />
                <Tab
                  eventKey={dropdownTab}
                  title={
                    <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic" className="border-0 p-0 " style={{ color: activeTab == "all" || activeTab == "active" ? "#6C757D" : "black", }}>
                        {getDropdownLabel()}
                        <UilAngleDown />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setDropdownTab("inactive");
                            setActiveTab("inactive");
                          }}
                        >
                          Inactive ({inactiveCount})
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setDropdownTab("pending");
                            setActiveTab("pending");
                          }}
                        >
                          Pending ({pendingCount})
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setDropdownTab("cancelled");
                            setActiveTab("cancelled");
                          }}
                        >
                          Cancelled ({cancelledCount})
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                />
              </Tabs>
            </Col>
          </Row>
          <Row className="mb-5 px-3">
            <Col xs={12} sm={12} md={7} lg={7}>
              <SearchComponent searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            </Col>
          </Row>
          <div style={{
            height: "calc(100% - 110px)",
            overflowY: "auto",
            overflowX: "hidden",
          }} >
            <Row >
              {finalFilteredData.length > 0 ? (
                [...finalFilteredData]
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((data, index) => {
                  return (
                    <>
                      {!isMobile ? <Col xs={12} sm={12} md={7} lg={7}>
                        <Card
                          key={index}
                          className="mb-5 border "
                          onClick={() => handleRowClick(data)}
                        >
                          <Card.Header
                            className="py-4 d-flex justify-content-between"
                            style={{ borderBottom: "1px solid #DEE2E6" }}
                          >
                            <div className="d-flex align-items-center">
                              <text style={{ fontSize: '13px' }}>{data?.subscription_number}</text>
                              <div
                                className="mx-3 px-2"
                                style={{
                                  backgroundColor: getStatusColor(data?.status).bg,
                                  color: getStatusColor(data?.status).text,
                                  fontSize: "13px",
                                  borderRadius: "5px",
                                }}
                              >
                                <text style={{ fontSize: "13px", }}>{capitalizeFirstLetter(data?.status)}</text>
                              </div>
                            </div>
                            <div>
                              <text style={{ fontSize: "13px", }}>
                                {data?.bill_queue?.start_date
                                  ? formatDate(data?.bill_queue?.start_date)
                                  : ""}
                              </text>
                            </div>
                          </Card.Header>

                          <Card.Body className="py-4 px-6">

                            <div className="d-flex justify-content-between align-items-center">
                              <text style={{ fontSize: "13px", color: 'gray' }}>Name</text>
                              <text style={{ fontSize: "13px", color: 'gray' }}>Amount</text>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-1">
                              <text style={{ fontSize: "13px", }}>
                                {data?.product?.name} -{" "}
                                {data?.subscription_vouchers?.[0]?.voucher?.voucher_items?.[0]
                                  ?.product_name}
                              </text>
                              <text style={{ fontSize: "13px", }}><GlobalCurrency price={data?.grand_total} /></text>
                            </div>


                            <div className="d-flex justify-content-between align-items-center mt-5">
                              <text style={{ fontSize: "13px", color: 'gray' }}>Plan Price</text>
                              {data?.subscription_items?.[1]?.price && (
                                <text style={{ fontSize: "13px", color: 'gray' }}>Addon Price</text>
                              )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-1">
                              <text style={{ fontSize: "13px", }}><GlobalCurrency price={data?.subscription_items?.[0]?.price || 0} /></text>
                              {data?.subscription_items?.[1]?.price && (
                                <text style={{ fontSize: "13px", }}><GlobalCurrency price={data?.subscription_items?.[1]?.price || 0} /></text>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                        : <MembshipListCard item={data} index={index} handleRowClick={handleRowClick} />}
                    </>
                  );
                })
              ) : (
                <div className="text-center text-muted">No data found</div>
              )}
            </Row>
          </div>
          {loading && <LoadingSpinner />}
        </div>
      </Container>
    </Layout >
  );
};

export default Subscription;
