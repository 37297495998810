import { Col, Row } from "react-bootstrap";

import { placeOfSupplyList } from "../../shared/components/PlaceOfSupply";

const PartyDetails = ({ data }) => {
  const state =
    placeOfSupplyList.find((value) => value?.value == data?.party_addresses[0]?.state);
  
  return (
    <>
      <Row>
        <Col xs={12}>
          <text className="mb-0" style={{ fontSize: '13px', fontWeight: 500 }}>{data?.name}</text>
        </Col>
        {data?.party_addresses[0]?.address && (
          <Col xs={12} className="d-flex flex-column">
            <text style={{ fontSize: "13px" }}>{data?.party_addresses[0]?.address} ,{" "}</text>
            <text style={{ fontSize: "13px" }}>{data?.party_addresses[0]?.city} , {state.label} -{" "} {data?.party_addresses[0]?.pincode}</text>
          </Col>
        )}
        <Col xs={12}>
          <text className="mb-0" style={{ fontSize: "13px" }}>
            Phone : {data?.phone_number}
          </text>
        </Col>
      </Row>
    </>
  );
};

export default PartyDetails;
