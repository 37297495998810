import { Badge, Col, Modal, Row } from "react-bootstrap";
import GlobalCurrency from "../../shared/helper/GlobalCurrency";
import { getLocalizedDate } from "../../shared/helper/dateUtils";



const PaymentModal = ({ show, setShow, paymentData }) => {
    return (
        <>
            <Modal size="md" show={show} onHide={() => setShow(false)} centered>
                <Modal.Header className="px-6" closeButton>
                    <Modal.Title ># {paymentData?.payment?.payment_number}  <Badge bg={paymentData?.payment?.status == "open" ? "success" : "danger"}>{paymentData?.payment?.status == "open" ? "Open" : "Close"}</Badge></Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-6 py-5">
                    <Row>
                        <Col lg={6} sm={6} md={6} xs={7}>
                            <div className="d-flex flex-column">
                                <div>
                                    <text style={{ color: 'gray', fontSize: '13px' }}>Payment Date</text>
                                </div>
                                <div className="mt-1">
                                    <text>{getLocalizedDate(paymentData?.payment?.payment_date)}</text>
                                </div>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-between" lg={6} md={6} sm={6} xs={5}>
                            <div className="d-flex flex-column">
                                <div>
                                    <text style={{ color: 'gray', fontSize: '13px' }}>Mode</text>
                                </div>
                                <div className="py-0 " style={{
                                    borderRadius: "5px",
                                    color: paymentData?.payment?.mode === "card"
                                        ? "#FF6F00"
                                        : paymentData?.payment?.mode === "upi"
                                            ? "#003C8F"
                                            : paymentData?.payment?.mode === "cash"
                                                ? "#155724"
                                                : "black",
                                }}> <text>{paymentData?.payment?.mode
                                    ? paymentData.payment.mode.charAt(0).toUpperCase() + paymentData.payment.mode.slice(1)
                                    : ""}</text></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="d-flex justify-content-between" lg={6} md={6} sm={6} xs={7}>
                            <div className="d-flex flex-column">
                                <div>
                                    <text style={{ color: 'gray', fontSize: '13px' }}>Amount</text>
                                </div>
                                <div className="mt-1">
                                    <text><GlobalCurrency price={paymentData?.payment?.amount} /></text>
                                </div>
                            </div>
                        </Col>
                        <Col className="" lg={6} md={6} sm={6} xs={5}>
                            <div className="d-flex flex-column">
                                <div>
                                    <text style={{ color: 'gray', fontSize: '13px' }}>Balance</text>
                                </div>
                                <div className="mt-1">
                                    <text><GlobalCurrency price={paymentData?.payment?.balance} /></text>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="" lg={6} md={6} sm={6} xs={7}>
                            <div className="d-flex flex-column">
                                <div>
                                    <text style={{ color: 'gray', fontSize: '13px' }}>Type</text>
                                </div>
                                <div className="mt-1">
                                    <text>{paymentData?.payment?.type == "payment_in" ? "Payment In" : "Payment Out"}</text>
                                </div>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-between" lg={6} md={6} sm={6} xs={5}>
                            <div className="d-flex flex-column">
                                <div>
                                    <text style={{ color: 'gray', fontSize: '13px' }}> Reference No</text>
                                </div>
                                <div className="mt-1">
                                    <text style={{}}>{paymentData?.payment?.reference_number || '-'}</text>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col lg={6} md={6} sm={6} xs={7}>
                            <div className="d-flex flex-column">
                                <div>
                                    <text style={{ color: 'gray', fontSize: '13px' }}>Created At</text>
                                </div>
                                <div className="mt-1">
                                    <text>{getLocalizedDate(paymentData?.created_at)}</text>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentModal;