import Header from "../shared/Header";
import Layout from "../shared/components/Layout";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ActiveSubscription from "./components/ActiveSubscription";
import TotalDue from "./components/TotalDue";
import NextDueDate from "./components/NextDueDate";
import { useEffect } from "react";
import { getAllPaymentsWithPartyId, getAllSubscriptionVoucher, getSubscription } from "../subscription/store/subscriptionSlice";
import OverDue from "./components/OverDue";
import { UilSync } from '@iconscout/react-unicons'
import LoadingSpinner from "../shared/helper/LoadingSpinner";

const HomePage = (props) => {
  const { isMobile } = props
  const dispatch = useDispatch();
  const { party, accessToken, tenantId, } = useSelector((state) => state.auth);

  const { loading } = useSelector((state) => state.subscription);


  useEffect(() => {
    const partyId = party?.party_id
    dispatch(getSubscription({ partyId, accessToken, tenantId }))
    dispatch(getAllSubscriptionVoucher({ partyId, accessToken, tenantId }));
    dispatch(getAllPaymentsWithPartyId({ partyId, accessToken, tenantId }));
  }, [party]);


  const handleRefresh = () => {
    const partyId = party?.party_id
    dispatch(getSubscription({ partyId, accessToken, tenantId }))
    dispatch(getAllSubscriptionVoucher({ partyId, accessToken, tenantId }));
    dispatch(getAllPaymentsWithPartyId({ partyId, accessToken, tenantId }));
  }



  return (
    <Layout>
      <Header title="Home" ItemAction={<UilSync className={!isMobile ? 'mx-3 mt-2' : 'mt-2'} onClick={handleRefresh} size="20" color={isMobile ? "white" : 'gray'} />} />
      <Container className="p-2" fluid style={{
        // height: "calc(100vh - 50px)",
        // overflowy: "auto",
        // overflowX: "hidden",
        // backgroundColor:'#ECEFF1'
      }}>

        <Row className="mt-3 px-3">
          <Col lg={4} md={4} sm={6} xs={6} className="">
            <ActiveSubscription />
          </Col>
          <Col lg={4} md={4} sm={6} xs={6} className="">
            <TotalDue />
          </Col>
        </Row>
        <Row className="mt-5 px-3">
          <Col lg={4} md={4} sm={6} xs={6} className="">
            <NextDueDate />
          </Col>
          {/* <Col lg={3} className="mt-4">
              <LastPaid />
            </Col> */}
          <Col lg={4} md={4} sm={6} xs={6} className="">
            <OverDue />
          </Col>
        </Row>

        {loading && <LoadingSpinner />}
      </Container>
    </Layout>
  );
};
export default HomePage;
