import { Button, Col, Container, Row } from "react-bootstrap";
import PartyDetails from "./PartyDetails";
import PaidDetails from "./PaidDetails";
import GlobalCurrency from "../../shared/helper/GlobalCurrency";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from "../../shared/helper/LoadingSpinner";
import PaymentDetails from "./PaymentDetails";

const OrderDetails = ({ data, pdfRef, party, handlePrint, loading, payment }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  const handleGoBack = () => {
    navigate(-1);
  };


  const getStatus = (item) => {
    console.log("item status", item)
    if (item?.voucher?.paid_amount === item?.voucher?.grand_total) return "Paid";
    if (item?.voucher?.paid_amount > 0 && item?.voucher?.paid_amount < item?.voucher?.grand_total)
      return "Partially Paid";
    return "Unpaid";
  };

  const status = getStatus(data);

  const getStatusStyles = (status) => {

    const statusStyles = {
      Paid: { backgroundColor: "#D4EDDA", color: "#155724" },
      Unpaid: { backgroundColor: "#F8D7DA", color: "#721C24" },
      "Partially Paid": { backgroundColor: "#FFECB3", color: "#FF8F00" },
    };

    return statusStyles[status] || { backgroundColor: "#E0E0E0", color: "#333" };
  };




  return (
    <>
      <Container fluid className="p-2">
        <div className="px-2 py-3" style={{ backgroundColor: 'white', height: "calc(100vh - 80px)", borderRadius: '5px' }}>
          <Row>
            <Col xs={12} className=" px-4 py-2 d-flex justify-content-start align-items-center">
              <FontAwesomeIcon onClick={handleGoBack}
                className="mx-2"
                icon={faArrowLeft}
                style={{ cursor: "pointer" }}
                fontSize={20}
              />
              <text className="mb-0 mx-1" style={{ fontSize: "16px", fontWeight: 600 }}>
                #&nbsp;{data?.voucher?.voucher_number}  &nbsp; &nbsp;
              </text>
              {status && (
                <div
                  className="px-2"
                  style={{
                    ...getStatusStyles(status),
                    fontSize: "13px",
                    borderRadius: "5px",
                    fontWeight:700
                  }}
                >
                  {status}
                </div>
              )}
            </Col>
          </Row>
          <div ref={pdfRef} style={{
            height: "calc(100% - 200px)",
            overflow: "auto",
            overflowX: "hidden",
          }}>
            <Row className="">
              <Col xs={12} md={6} className="px-6 py-2">
                <div className="d-flex justify-content-between col-12">
                  <div>
                    <PartyDetails data={party} />
                  </div>

                  <div>
                    <text
                      className="mb-0 form-label"
                      style={{ fontSize: "13px", fontWeight: 500, color: 'gray' }}
                    >
                      {"Subscription No"}
                    </text>
                    <br />
                    <text style={{ fontSize: "13px" }}>
                      {data?.subscription?.subscription_number}
                    </text>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} className="px-6 ">
                <div className="d-flex justify-content-between">
                  <div>
                    <text
                      className="mb-0"
                      style={{ fontSize: "13px", fontWeight: 500, color: 'gray' }}
                    >
                      Invoice No
                    </text>
                    <br />
                    <text style={{ fontSize: "13px" }}>
                      {data?.voucher?.voucher_number}
                    </text>
                  </div>
                  <div>
                    <text
                      className="mb-0"
                      style={{ fontSize: "13px", fontWeight: 500, color: 'gray' }}
                    >
                      Invoice Date
                    </text>
                    <br />
                    <text style={{ fontSize: "13px" }}>
                      {formatDate(data?.bill_date)}
                    </text>
                  </div>
                </div>

              </Col>
              <Col xs={12} className="px-6 py-3" >
                <div className="py-2" style={{
                  borderTop: "1px solid lightgray",
                  borderBottom: "1px solid lightgray",
                }}>
                  <text className="mb-0" style={{ fontSize: '14px', fontWeight: 600 }}>
                    Item Details
                  </text>
                </div>
              </Col>
              <Col xs={12} className="px-6 " >
                {data?.voucher?.voucher_items.length > 0 && data?.voucher?.voucher_items?.map((data, index, array) => {
                  return (
                    <div key={index}>
                      <div className="d-flex justify-content-between">
                        <text style={{ fontSize: '13px', }}>{data?.product_name}</text>
                        <text style={{ fontSize: '13px', }}>
                          <GlobalCurrency price={data?.item_price || 0} />
                        </text>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <text style={{ fontSize: '13px', }}>QTY &nbsp;X &nbsp;  {data?.quantity}</text>
                        <text style={{ fontSize: '13px', }}>{data?.quantity}</text>
                      </div>
                      {data?.item_discount_amount > 0 && <div className="d-flex justify-content-between mt-2">
                        <text style={{ fontSize: '13px', }}>Discount</text>
                        <text style={{ fontSize: '13px', }}><GlobalCurrency price={data?.item_discount_amount || 0} /></text>
                      </div>}
                      {index !== (array.length - 1) && <hr />}
                    </div>
                  )
                })}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="px-6" >
                <hr />
                <PaidDetails data={data} />
                <hr />
              </Col>
            </Row>
            <Row>
              {data?.voucher?.tnc ? (
                <>
                  <Col className="px-6" xs={6} style={{ borderRight: '1px solid lightgray' }}>
                    <text className="mb-0" style={{ fontSize: "13px", fontWeight: 500 }}>
                      Terms And Conditions
                    </text>
                    <br />
                    <text className="mb-0" style={{ fontSize: "13px" }}>
                      {data?.voucher?.tnc}
                    </text>
                  </Col>
                  <Col className="px-6" xs={6}>
                    <text className="mb-0" style={{ fontSize: "13px", fontWeight: 500 }}>
                      AUTHORIZED SIGNATORY FOR
                    </text>
                    <br />
                    <div className="border mb-2" style={{ height: "50px" }}></div>
                  </Col>
                </>
              ) : (
                <Col className="px-6 " lg={8} xs={10}>
                  <text className="mb-2" style={{ fontSize: "13px", fontWeight: 500 }}>
                    AUTHORIZED SIGNATORY FOR
                  </text>
                  <br />
                  <div className="border mb-2" style={{ height: "50px" }}></div>
                </Col>
              )}
            </Row>
            <Row className="mt-3 mb-2">
              <Col xs={12} className="px-6" >
                {!loading && <Button variant="outline-primary w-100 px-2" onClick={handlePrint}>
                  <UilImport className="mx-2 mb-1" size="16" />
                  Download PDF
                </Button>}
                {loading && <LoadingSpinner />}
              </Col>
            </Row>

          </div>
          <div className="mt-1">
            <Row>
              <Col xs={12} className="px-6" >
                <PaymentDetails payment={payment} />
              </Col>
            </Row>
          </div>
        </div>
      </Container>


    </>
  );
};

export default OrderDetails;
