import { useEffect, useMemo, useState } from "react";
import Header from "../../shared/Header";
import Layout from "../../shared/components/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPaymentsWithPartyId,
  getAllSubscriptionVoucher,
  getSubscription,
  getSubscriptionVoucher,
} from "../../subscription/store/subscriptionSlice";
import { Badge, Card, Col, Container, FormControl, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalizedDate } from "../../shared/helper/dateUtils";
import { UilSearch, UilSync } from '@iconscout/react-unicons'
import GlobalCurrency from "../../shared/helper/GlobalCurrency";
import LoadingSpinner from "../../shared/helper/LoadingSpinner";
import InvoiceListCard from "../components/InvoiceListCard";
import SearchComponent from "../../shared/components/SearchComponent";

const InvoicesIndex = ({ isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state;
  //console.log("state",state)

  const { getSubscriptionData, voucher, loading } = useSelector(
    (state) => state.subscription
  );
  const { party, accessToken, tenantId } = useSelector((state) => state.auth);

  const filterdata =
    voucher &&
    voucher.length > 0 &&
    voucher.filter((value) => value?.party_id === party?.party_id);



  const [filteredData, setFilteredData] = useState(filterdata || []);
  const [activeTab, setActiveTab] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");


  useEffect(() => {
    if (state == "unpaid") {
      setActiveTab("unpaid")
    } else {
      setActiveTab("all")
    }

  }, [])

  const finalFilteredData = useMemo(() => {
    if (!filterdata) return [];

    let filtered = [...filterdata];

    if (activeTab !== "all") {
      filtered = filtered.filter((data) => {
        if (activeTab === "paid") return data?.paid_amount === data?.grand_total;
        if (activeTab === "unpaid") return data?.paid_amount === 0;
        return false;
      });
    }

    if (searchQuery) {
      filtered = filtered.filter((data) =>
        data?.voucher_number?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return filtered;
  }, [activeTab, searchQuery, filterdata]);


  useEffect(() => {
    const partyId = party?.party_id;
    if (partyId && tenantId && accessToken) {
      dispatch(getSubscription({ partyId, tenantId, accessToken }));
    }
  }, [party, tenantId, accessToken]);

  const handleRowClick = (row) => {

    const partyId = row?.id;
    dispatch(
      getSubscriptionVoucher({
        partyId: partyId,
        tenantId: tenantId,
        accessToken: accessToken,
      })
    );
    const rowData =
      getSubscriptionData &&
      getSubscriptionData.filter(
        (value) => value.id === row?.voucher_subscription?.subscription_id
      );
    const subVoucher = rowData[0]?.subscription_vouchers;
    const findVoucher =
      subVoucher &&
      subVoucher.length > 0 &&
      subVoucher.filter((value) => value?.voucher_id === row?.id);

    navigate("/subscription/invoice", {
      state: { data: findVoucher[0], party: rowData[0]?.party, payment: row?.voucher_payments },
    });
  };

  const getStatus = (data) => {
    if (data?.paid_amount === data?.grand_total) return "Paid";
    if (data?.paid_amount > 0 && data?.paid_amount < data?.grand_total)
      return "Partially Paid";
    return "Unpaid";
  };

  const getStatusStyles = (status) => {
    const statusStyles = {
      Paid: { backgroundColor: "#D4EDDA", color: "#155724" },
      Unpaid: { backgroundColor: "#F8D7DA", color: "#721C24" },
      "Partially Paid": { backgroundColor: "#FFECB3", color: "#FF8F00" },
    };

    return statusStyles[status] || { backgroundColor: "#E0E0E0", color: "#333" };
  };

  const getInvoiceCounts = (data) => {
    if (!data || data.length === 0) return { all: 0, paid: 0, unpaid: 0 };

    const paidCount = data.filter(
      (invoice) => invoice.paid_amount === invoice.grand_total
    ).length;

    const unpaidCount = data.filter(
      (invoice) => invoice.paid_amount === 0
    ).length;

    return {
      all: data.length,
      paid: paidCount,
      unpaid: unpaidCount,
    };
  };

  const handleRefresh = () => {
     const partyId = party?.party_id
     dispatch(getSubscription({ partyId, accessToken, tenantId }))
     dispatch(getAllSubscriptionVoucher({ partyId, accessToken, tenantId }));
     dispatch(getAllPaymentsWithPartyId({ partyId, accessToken, tenantId }));
   }


  return (
    <Layout>
      <Header title={"Invoices"} ItemAction={<UilSync className={!isMobile ? 'mx-3 mt-2' : 'mt-2'} onClick={handleRefresh} size="20" color={isMobile ? "white" : 'gray'} />} />
      <Container
        className="px-2 py-2"
        fluid
      // style={{
      //   height: "calc(100vh - 80px)",
      //   overflowY: 'hidden',
      //   overflowX: "hidden",
      // }}
      >
        <div className=" py-3" style={{ backgroundColor: 'white', height: "calc(100vh - 80px)", borderRadius: '5px' }}>
          <Row>
            <Col lg={12}> <Tabs
              activeKey={activeTab}
              onSelect={(key) => setActiveTab(key)}
              id="subscription-tabs"
              className="mb-5 "
              variant="underline"
            //style={{ borderBottom: "1px solid lightgray" }}
            >
              <Tab eventKey="all" title={`All (${getInvoiceCounts(filterdata).all})`} />
              <Tab eventKey="paid" title={`Paid (${getInvoiceCounts(filterdata).paid})`} />
              <Tab eventKey="unpaid" title={`Unpaid (${getInvoiceCounts(filterdata).unpaid})`} />
            </Tabs></Col>
          </Row>
          <Row className="mb-5 px-3">
            <Col xs={12} sm={12} md={7} lg={7}>
              <SearchComponent searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            </Col>
          </Row>
          <div style={{
            height: "calc(100% - 110px)",
            overflowY: "auto",
            overflowX: "hidden",
          }} >
            <Row>
              {finalFilteredData && finalFilteredData.length > 0 ? (
                 [...finalFilteredData]
                 .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                 .map((data, index) => {
                  const status = getStatus(data);
                  return (
                    <>
                      {!isMobile ?
                        <Col xs={12} md={7} lg={7} className="">
                          <Card
                            key={index}
                            className="mb-5 border"
                            onClick={() => handleRowClick(data)}
                          >
                            <Card.Header
                              className="d-flex justify-content-between py-3"
                              style={{
                                fontSize: "13px",
                                fontWeight: 400,
                                borderBottom: "1px solid #DEE2E6",
                                backgroundColor: "#F8F9FA",
                              }}
                            >
                              <text style={{ fontSize: '13px' }}>{data?.voucher_number}</text>
                              <text style={{ fontSize: '13px' }}>{getLocalizedDate(data?.created_at)}</text>
                            </Card.Header>
                            <Card.Body className="px-6 py-3">
                              <Row>
                                <Col lg={12}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <text style={{ fontSize: '13px' }}>
                                      {data?.voucher_items[0]?.product_name} -{" "}
                                      {data?.voucher_items[0]?.product_code}
                                    </text>
                                    <text style={{ fontSize: '13px' }}><GlobalCurrency price={data?.grand_total} /></text>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col lg={12}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-column justify-content-start">
                                      <text style={{ color: "gray", fontSize: '13px' }}>Paid Amount</text>
                                      <text className="mt-1" style={{ fontSize: '13px' }}><GlobalCurrency price={data?.paid_amount} /></text>
                                    </div>
                                    <div className="d-flex flex-column justify-content-start align-items-end">
                                      <text style={{ color: "gray", fontSize: '13px' }}>Status</text>
                                      {/* {status && (
                                <div
                                  className="px-2"
                                  style={{
                                    ...getStatusStyles(status),
                                    fontSize: "13px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {status}
                                </div>
                              )} */}

                                      <div className="d-flex flex-column">
                                        {/* <text style={{ fontSize: '12px', color: 'gray' }}>Status</text> */}
                                        {data?.paid_amount === data?.grand_total ? (
                                          <Badge bg="success">Paid</Badge>
                                        ) : data?.paid_amount > 0 ? (
                                          <Badge bg="warning">Partial Paid</Badge>
                                        ) : (
                                          <Badge bg="danger">Unpaid</Badge>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Col>

                              </Row>
                            </Card.Body>
                          </Card>
                        </Col> : <InvoiceListCard item={data} index={index} handleRowClick={handleRowClick} />}
                    </>
                  );
                })
              ) : (
                <div className="text-center text-muted">No data found</div>
              )}
            </Row>
          </div>
          {loading && <LoadingSpinner />}
        </div>
      </Container>
    </Layout>
  );
};

export default InvoicesIndex;