import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GlobalCurrency from "../../../shared/helper/GlobalCurrency";
import { getLocalizedDate } from "../../../shared/helper/dateUtils";
import { useSelector } from "react-redux";



const MobileInvoiceList = ({ state }) => {
    const navigate = useNavigate();



    const { getSubscriptionData, voucher } = useSelector(
        (state) => state.subscription
    );



    const handleRowClick = (data) => {

        const voucherId = state?.subscription_vouchers?.[0]?.voucher?.id;

        const voucherPayment = voucher && voucher?.length > 0 && voucher?.filter(value => value?.id == data?.voucher_id)


        if (voucherId) {
            navigate(`/subscription/invoice`, { state: { data: data, party: state?.party, payment: voucherPayment[0]?.voucher_payments } });
        }
    };


    const getStatus = (item) => {
        console.log("item status", item)
        if (item?.voucher?.paid_amount === item?.voucher?.grand_total) return "Paid";
        if (item?.voucher?.paid_amount > 0 && item?.voucher?.paid_amount < item?.voucher?.grand_total)
            return "Partially Paid";
        return "Unpaid";
    };


    const getStatusStyles = (status) => {

        const statusStyles = {
            Paid: { backgroundColor: "#D4EDDA", color: "#155724" },
            Unpaid: { backgroundColor: "#F8D7DA", color: "#721C24" },
            "Partially Paid": { backgroundColor: "#FFECB3", color: "#FF8F00" },
        };

        return statusStyles[status] || { backgroundColor: "#E0E0E0", color: "#333" };
    };

    return (
        <>
            {state?.subscription_vouchers?.length > 0 &&
                [...state.subscription_vouchers]
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .map((data, index) => {
                        const status = getStatus(data);
                        return (
                            <div key={index} onClick={() => handleRowClick(data)}>
                                <div className="mb-2 d-flex justify-content-between">
                                    <div className="d-flex flex-column">
                                        {/* <text style={{ fontSize: '12px', color: 'gray' }}>Voucher No</text> */}
                                        <text style={{ fontSize: '13px' }}># {data?.voucher?.voucher_number}</text>
                                    </div>
                                    <div className="d-flex flex-column">
                                        {/* <text style={{ fontSize: '12px', color: 'gray' }}>Created At</text> */}
                                        <text style={{ fontSize: '13px' }}>{getLocalizedDate(data?.created_at)}</text>
                                    </div>
                                </div>
                                <div className="mb-2 d-flex justify-content-between">
                                    <div className="d-flex flex-column">
                                        {/* <text style={{ fontSize: '12px', color: 'gray' }}>Voucher No</text> */}
                                        <text style={{ fontSize: '13px' }}>{state?.party?.name}</text>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <text style={{ fontSize: '13px' }}><GlobalCurrency price={data?.voucher?.grand_total || 0} /></text>

                                    </div>
                                </div>
                                <div className="mb-2 d-flex justify-content-between align-items-center">

                                    <div className="d-flex flex-column">
                                        <text style={{ fontSize: '13px', color: 'gray' }}>Paid Amount</text>
                                        <text style={{ fontSize: '13px' }}><GlobalCurrency price={data?.voucher?.paid_amount || 0} /> </text>
                                    </div>
                                    <div className="d-flex flex-column">
                                        {/* <text style={{ fontSize: '12px', color: 'gray' }}>Status</text> */}
                                        {status && (
                                            <div
                                                className="px-2"
                                                style={{
                                                    ...getStatusStyles(status),
                                                    fontSize: "13px",
                                                    borderRadius: "5px",
                                                    fontWeight: 700
                                                }}
                                            >
                                                {status}
                                            </div>
                                        )}
                                        {/* {data?.voucher?.paid_amount === data?.voucher?.grand_total ? (
                                        <Badge bg="success">Paid</Badge>
                                    ) : data?.voucher?.paid_amount > 0 ? (
                                        <Badge bg="warning">Partial Paid</Badge>
                                    ) : (
                                        <Badge bg="danger">Unpaid</Badge>
                                    )} */}
                                    </div>
                                </div>
                                <hr />
                            </div>
                        );
                    })}

        </>
    )
}

export default MobileInvoiceList;