import { Card, Col, Row } from "react-bootstrap";
import {  UilAngleRight } from '@iconscout/react-unicons'
import GlobalCurrency from "../../shared/helper/GlobalCurrency";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";



const OverDue = () => {
    const navigate = useNavigate();
    const { getSubscriptionData, voucher } = useSelector(
        (state) => state.subscription
    );

    const { party } = useSelector((state) => state.auth);

    const filterdata =
        voucher &&
        voucher.length > 0 &&
        voucher.filter((value) => value?.party_id === party?.party_id);


    const currentDate = new Date();

    const overdueVouchers =
        filterdata &&
        filterdata.length > 0 &&
        filterdata
            .filter((v) => {
                const dueDate = new Date(v.due_date);
                dueDate.setHours(0, 0, 0, 0);
                currentDate.setHours(0, 0, 0, 0);
                return dueDate < currentDate && v.grand_total > v.paid_amount;
            })
            .map((v) => ({
                voucher_number: v.voucher_number,
                due_date: v.due_date,
                grand_total: v.grand_total,
                paid_amount: v.paid_amount,
                overdue_amount: v.grand_total - v.paid_amount,
            }));

    console.log("overdueVouchers", overdueVouchers)


    const totalOverdueAmount = overdueVouchers && overdueVouchers.length > 0 && overdueVouchers.reduce((acc, value) => acc + value.overdue_amount, 0);

    const handleNavigate = () => {
        navigate("/subscription/invoices", { state: "unpaid" });
    }


    return (
        <>
            <Card
                className="shadow-xs"
                style={{
                    backgroundColor: "white",
                    //boxShadow: "0 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
                    border: "1px solid #dee2e6",
                }}
                onClick={handleNavigate}
            >
                <Card.Body className="py-3 px-2">
                    <Row>
                        <Col lg={12} sm={12} xs={12}>
                            <div className="d-flex justify-content-between">
                                {/* <UilClockSeven size="16" color="red" /> */}
                                <text className="mx-2 bold" style={{ fontSize: "10px",color:"red" }}>
                                    Overdue
                                </text>
                                <UilAngleRight size="20" color="gray" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={12} sm={12} xs={12}>
                            <div className="d-flex justify-content-between">
                                <text className="mx-2 mb-0 " style={{ fontSize: "15px", }}>
                                    <GlobalCurrency price={totalOverdueAmount || 0} />
                                </text>
                              
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default OverDue;