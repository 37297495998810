import { useEffect, useState } from "react";
import { Row, Col, Button, Image, Container, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { findTenant } from "./store/authSlice";
import appLogo from "../../assets/images/fitPlusLogo.png"
const MultiTenants = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { party, isFindTenant, isTenantFinding } = useSelector((state) => state.auth)
    const associateTenants = JSON.parse(party.associate_tenants)
    const [selectedTenant, setSelectedTenant] = useState(associateTenants[0])

    useEffect(() => {
        if (isFindTenant) {
            
            navigate('/home')
        }
    }, [isFindTenant])

    const handleContinue = () => {
        
        dispatch(findTenant(selectedTenant.business_id));
    }


    return (
        <>
            <Container fluid className="bg-white"
                style={{
                    height: "calc(100vh - 10px)",
                    overflowX: "hidden",
                    overflowY: "auto",
                    backgroundRepeat: "repeat",
                    backgroundSize: "auto",
                }}>
                <div className="d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh - 10px)' }}>
                    <Card className="w-100 w-md-75 w-lg-50 border"
                        style={{
                            maxWidth: '450px',
                            height: '570px',
                            overflow: 'hidden'
                        }}>
                        <Card.Header>
                            <Col className="d-flex justify-content-center">
                                <Image src={appLogo} alt="FitplusClub+" style={{ height: "75px" }} />
                            </Col>
                        </Card.Header>
                        <Card.Body className="d-flex flex-column">
                            <Row>
                                <Col lg={12}>
                                    <h3 className="mb-3">Choose your business</h3>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-3" style={{ height: 'calc(280px - 10px)', overflowY: 'auto' }}>
                                <Col lg={12}>
                                    {associateTenants?.length > 0 &&
                                        associateTenants?.map((data, index) => (
                                            <div key={index} >
                                                <div
                                                    className="d-flex col-lg-12 justify-content-start mb-6 shadow-sm border p-2"
                                                    style={{
                                                        borderRadius: "10px",
                                                        backgroundColor: "#FBFBFB",
                                                    }}
                                                    onClick={() => { 
                                                        setSelectedIndex(index);
                                                        setSelectedTenant(data);
                                                    }}
                                                >
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input cursor-pointer"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            id={`flexRadioDefault${index}`}
                                                            checked={selectedIndex === index}
                                                            onChange={() => {
                                                                setSelectedIndex(index);
                                                                setSelectedTenant(data);
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label cursor-pointer"
                                                            htmlFor={`flexRadioDefault${index}`}
                                                        >
                                                            {data.business_name}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col lg={12}>
                                    {!isTenantFinding && <Button className="w-100" onClick={handleContinue}>Continue</Button>}

                                    {isTenantFinding && <div class="spinner-border text-primary" role="status">
                                    </div>}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>

            </Container>

        </>
    )

}


export default MultiTenants;
