import { FormControl, InputGroup } from "react-bootstrap"
import { UilSearch } from '@iconscout/react-unicons'


const SearchComponent = ({ searchQuery, setSearchQuery }) => {
    return (
        <div className="d-flex justify-content-start align-items-center border " style={{ borderRadius: '5px',backgroundColor:'#ECEFF1' }}>
            <UilSearch className="mx-2 bold" size="18" color="#1A237E" />
            <input className="form-control px-1" type="text" placeholder="Search.." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
                backgroundColor:'#ECEFF1'
            }} />
            {/* <FormControl 0xFFECEFF1
                    type="text"
                    placeholder="Search.."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="ps-5"
                /> */}
        </div>
    )
}

export default SearchComponent;