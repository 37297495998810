import React from "react";
import DataTable from "react-data-table-component";
import { taxList } from "../../shared/helper/taxConfig";

const SubscriptionDetailsTable = ({ data }) => {
  const columns = [
    {
      name: "Item",
      selector: (row) => row.productName || "N/A",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type || "N/A",
      sortable: true,
    },
    {
      name: "Qty",
      selector: (row) => row.quantity || 0,
      sortable: true,
    },
    {
      name: "Discount",
      selector: (row) => row.discount || 0,
      sortable: true,
    },
    {
      name: "Rate",
      selector: (row) => `₹${row.price || 0}`,
      sortable: true,
    },
    {
      name: "Tax",
      selector: (row) => row.tax || "None",
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => `₹${row.amount || 0}`,
      sortable: true,
    },
  ];

  const tableData = [
    {
      productName: data?.subscription_items[0]?.product?.name,
      type: data?.subscription_items[0]?.type,
      quantity: data?.subscription_items[0]?.quantity,
      discount: data?.discount,
      price: data?.subscription_items[0]?.price,
      tax: data?.subscription_items[0]?.tax_code
        ? taxList.find((value) => value.code === data?.subscription_items[0]?.tax_code)?.label
        : "None",
      amount: data?.grand_total,
    },
    ...(data?.subscription_items[1]?.product?.name
      ? [
        {
          productName: data?.subscription_items[1]?.product?.name,
          type: data?.subscription_items[1]?.type,
          quantity: data?.subscription_items[1]?.quantity,
          discount: data?.discount,
          price: data?.subscription_items[1]?.price,
          tax: data?.subscription_items[1]?.tax_code
            ? taxList.find((value) => value.code === data?.subscription_items[1]?.tax_code)?.label
            : "None",
          amount: data?.grand_total,
        },
      ]
      : []),
  ];

  const customStyles = {
    table: {
      style: {
        minHeight: "350px",

      },
    },
    headCells: {
      style: {
        backgroundColor: "#f0f0f0",
        color: "#333",
        fontSize: "14px",
        fontWeight: "600",
      },
    },
  };

  return (
    <div className="">
      <DataTable
        columns={columns}
        data={tableData}
        pagination
        highlightOnHover
        responsive
        customStyles={customStyles}
      />
    </div>
  );
};

export default SubscriptionDetailsTable;
