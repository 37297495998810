export const formateDate = (inputDate) => {
  const date = new Date(inputDate); // Convert the input date string to a Date object
  const year = date.getFullYear(); // Get the year
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Get the month and pad with leading zero if needed
  const day = String(date.getDate()).padStart(2, "0"); // Get the day and pad with leading zero if needed
  return `${day}-${month}-${year}`; // Format the date as "YYYY-MM-DD"
};
export const addExpires = (date, days) => {
  const newDate = new Date(date); // Create a new Date object to avoid mutating the original date
  newDate.setDate(newDate.getDate() + days * 60); // Add the specified number of days to the date
  return newDate;
};

export const getPrevious = (date, days) => {
  //
  //
  const newDate = new Date(date); // Create a new Date object to avoid mutating the original date
  newDate.setDate(newDate.getDate() - days * 60); // Add the specified number of days to the date
  return newDate;
};
