import { useRef, React } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Stack,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, } from "@fortawesome/free-solid-svg-icons";
import TopPortion from "./invoiceLayout/TopPortion";
import TablePortion from "./invoiceLayout/TablePortion";
import BottomPortion from "./invoiceLayout/BottomPortion";
import { UilImport } from "@iconscout/react-unicons";

const InvoiceBody = (props) => {
  const { data, pdfRef, handleGoBack, handlePrint, party } = props;
  const pdf = useRef();
  const { business } = useSelector((state) => state.auth);
  const { getSubscriptionData, getSubscriptionStatus, selectId } = useSelector(
    (state) => state.subscription
  );


  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  return (
    <Container className="py-2" fluid>
      <Card
        style={{
          backgroundColor: "white",
          border: "1px solid lightgray",
          borderRadius: "0px",
          height: "calc(100vh - 75px)",
        }}
      >
        <Row className="py-3">
          <Col lg={6} className="px-4 ">
            <FontAwesomeIcon onClick={handleGoBack}
              className="mx-2"
              icon={faArrowLeft}
              style={{ cursor: "pointer" }}
              fontSize={18}
            />
            <Form.Label className="mb-0 mx-2" style={{ fontSize: "16px", fontWeight: 600 }}>
              {"#" + data?.voucher?.voucher_number}
            </Form.Label>
          </Col>
          <Col lg={6}>
            <Stack direction="horizontal" className="float-end mx-2">
              <Button variant="outline-primary " onClick={handlePrint}>
                <UilImport className="mx-2 mb-1" size="16" />
                Download PDF
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row
          className="py-3 mx-2 bg"
          style={{
            height: "calc(100vh - 80px)",
            overflow: "auto",
            overflowX: "hidden",
            backgroundColor: "rgb(242,242,242)",
            borderRadius: "5px",
          }}
        >
          <Col md={2}></Col>
          <Col md={8} className="bg-white py-2" style={{ boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)' }}>
            <div ref={pdfRef}>
              <div className="d-flex align-items-center mb-2">
                <Col className="py-1" xs={1}>
                  <Form.Label
                    className="mb-0"
                    style={{ fontSize: "11px", fontWeight: 600 }}
                  >
                    {"TAX INVOICE"}
                  </Form.Label>
                </Col>
                <Col xs={3} className="py-0 p-0">
                  <div className="border px-2">
                    <Form.Label
                      className="mb-0 p-0 m-0"
                      style={{ fontSize: "11px", fontWeight: 400 }}
                    >
                      {"ORIGINAL FOR RECIPIENT"}
                    </Form.Label>
                  </div>
                </Col>
              </div>
              <div className="border bg-white" >
                <TopPortion data={data} party={party} />

                <div className="" style={{ borderTop: '1px solid lightgray', }}>
                  <TablePortion data={data} />
                </div>
                <div style={{ borderTop: "1px solid lightgray", }}>
                  <BottomPortion data={data} />
                </div>


              </div>
            </div>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Card>
    </Container>
  );
};
export default InvoiceBody;
