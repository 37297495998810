import { useEffect, useRef, useState } from "react";
import { Col, Container, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DropDownNavigation from "./components/DropDownNavigation";
import { useDispatch, useSelector } from "react-redux";
import { changeToggleShow } from "./sharedSlice";
import { UilBars } from "@iconscout/react-unicons";
import { UilSetting, UilSignOutAlt } from "@iconscout/react-unicons";
import LogoutModal from "./components/Logout";
import { resetAuth } from "../login/store/authSlice";
import { resetSubscription } from "../subscription/store/subscriptionSlice";
import appLogo from "../../assets/images/fitPlusLogo.png"
import { UilUser, UilSync } from '@iconscout/react-unicons'

const Header = ({ title, ItemAction }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { party } = useSelector((state) => state.auth);
  console.log("party", party)

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // 
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };


    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const handleLogoutModal = () => {
    setShow(!show);
  };
  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetSubscription());
    navigate("/login");
  };

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <Container
      fluid
      className="py-2"
      style={{
        //color:'white',
        //height: "67px",
        background: isMobile ? "#1A237E" : "",
        // boxShadow: "1.0px 0.5px 7.0px hsl(0deg 0% 0% / 0.38)",
        borderBottom: '1px solid lightgray'
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-start align-items-center">
          {isMobile && <DropDownNavigation />}
          <h2 className={`${isMobile ? "mx-3 bold mt-4" : "mt-4 bold"}`} style={{ color: isMobile ? 'white' : 'black' }}>{title}</h2>
        </div>
        <div>
          {ItemAction}
        </div>

        {/* <div className="position-relative" ref={dropdownRef}>
          <div
            className="d-flex justify-content-center align-items-center "
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "20px",
              cursor: "pointer",
              border: isMobile ? '2px solid white' : '2px solid gray',
            }}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <UilUser size="18" color={isMobile ?"white" : 'gray'} />
          </div>

          {dropdownOpen && (
            <div
              className="position-absolute bg-white border shadow-sm py-2 px-3"
              style={{
                top: "45px",
                right: "0",
                width: "200px",
                borderRadius: "8px",
                zIndex: 1000,
              }}
            >
              <div className="d-flex justify-content-start align-items-center mt-2">
                <div
                  className="d-flex justify-content-center align-items-center border"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {party?.name ? (
                    <span style={{ fontSize: "25px", fontWeight: "bold", color: "#1A237E" }}>
                      {party.name.includes(" ")
                        ? party.name
                          .split(" ")
                          .slice(0, 2)
                          .map((word) => word[0].toUpperCase())
                          .join("")
                        : party.name[0].toUpperCase()}
                    </span>
                  ) : (
                    <UilUser size="18" color="white" />
                  )}
                </div>
                <div className="d-flex justify-content-center mx-4">
                  <text style={{ fontSize: '14px' }}>Hello {party?.name}</text>
                </div>
              </div>
              <hr />
              <div className="d-flex mb-2  px-2 cursor-pointer" onClick={handleLogoutModal}>
                <UilSignOutAlt size="22" className="me-2" color="red" />
                <text style={{ fontSize: '14px' }} className="text-gray">Logout</text>
              </div>
            </div>

          )}
        </div> */}
      </div>
      {show && <LogoutModal onConfirm={handleLogout} onCancel={setShow} />}

    </Container>
  );
};
export default Header;
