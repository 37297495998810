import React from "react";
import DataTable from "react-data-table-component";
import { getLocalizedDate } from "../../shared/helper/dateUtils";
import { useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";

const InvoicesTable = ({ data }) => {
  const navigate = useNavigate();


  const getStatus = (data) => {
    const paidAmount = data?.subscription_vouchers[0]?.voucher?.paid_amount || 0;
    const grandTotal = data?.grand_total || 0;
    if (paidAmount === grandTotal) return "Paid";
    if (paidAmount > 0 && paidAmount < grandTotal) return "Partially Paid";
    return "Unpaid";
  };




  const columns = [
    {
      name: "Date",
      selector: (row) => row.createdAt || "N/A",
      sortable: true,
    },
    {
      name: "Voucher Number",
      selector: (row) => row.voucherNumber || "N/A",
      sortable: true,
    },
    {
      name: "Grand Total",
      selector: (row) => `₹${row.grandTotal || 0}`,
      sortable: true,
    },
    {
      name: "Paid Amount",
      selector: (row) => `₹${row.paidAmount ?? 'N/A'}`,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row.createdAtFormatted || "N/A",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      sortable: true,
      cell: (row) => (
        <Badge
          bg={
            row.status === "Paid"
              ? "success"
              : row.status === "Partially Paid"
                ? "warning"
                : "danger"
          }
          style={{
            padding: "5px",
            borderRadius: "5px",
            color:
              row.status === "Paid"
                ? "white"
                : row.status === "Partially Paid"
                  ? "black"
                  : "white",
          }}
        >
          {row.status}
        </Badge>
      ),
    },
  ];


  const tableData = data?.subscription_vouchers?.map((item) => ({
    createdAt: item?.voucher?.created_at
      ? new Date(item.voucher.created_at).toLocaleDateString()
      : "N/A",
    voucherNumber: item?.voucher?.voucher_number,
    grandTotal: item?.voucher?.grand_total,
    paidAmount: item?.voucher?.paid_amount,
    createdAtFormatted: getLocalizedDate(item?.created_at),
    status: getStatus(data),
    originalData: item,
  }));

  const handleRowClick = (row) => {
    
    navigate('/subscription/invoice', { state: { data: row.originalData, party: data?.party } });
  };


  const customStyles = {
    table: {
      style: {
        minHeight: "350px",
        cursor:'pointer'
      },
    },
    headCells: {
      style: {
        backgroundColor: "#f0f0f0",
        color: "#333",
        fontSize: "14px",
        fontWeight: "600",
      },
    },
  };

  return (
    <DataTable
      columns={columns}
      data={tableData}
      pagination
      highlightOnHover
      responsive
      onRowClicked={handleRowClick}
      customStyles={customStyles}
    />
  );
};

export default InvoicesTable;
