import { Badge, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { formateDate } from "../../../shared/helper/dateConvertion";
import { UilSignOutAlt } from '@iconscout/react-unicons'



const Attendance = ({ state }) => {

    const attendanceHistory = useSelector((state) => state?.subscription?.attendance)


    const calculateDuration = (timeIn, timeOut) => {
        if (!timeIn || !timeOut) return '--';

        const startTime = new Date(`1970-01-01T${timeIn}`).getTime();
        const endTime = new Date(`1970-01-01T${timeOut}`).getTime();

        if (endTime < startTime) return '--'; // Handle invalid cases

        const diff = endTime - startTime;
        const minutes = Math.floor((diff / 1000) / 60);
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (hours > 0) {
            return `${hours} hr ${remainingMinutes} min`;
        } else {
            return `${remainingMinutes} min`;
        }
    };


    const calculateAttendance = (filteredAttendance) => {
        let totalMinutes = 0;
        let hours = 0;
        let minutes = 0;
        let averageHours = 0;

        filteredAttendance.forEach((entry) => {
            const timeIn = new Date(`1970-01-01T${entry?.time_in}`).getTime();
            const timeOut = entry?.time_out
                ? new Date(`1970-01-01T${entry?.time_out}`).getTime()
                : null;

            const duration = timeOut ? timeOut - timeIn : 0;

            totalMinutes += Math.floor(duration / (1000 * 60));
        });

        hours = Math.floor(totalMinutes / 60);
        minutes = totalMinutes % 60;
        const totalAttendance = filteredAttendance.length;
        averageHours = totalAttendance > 0 ? Math.floor(totalMinutes / totalAttendance) : 0;






        return { totalMinutes, hours, minutes, averageHours };
    };

    const { totalMinutes, hours, minutes, averageHours } = calculateAttendance(attendanceHistory);

    const groupedAttendance = attendanceHistory.reduce((acc, record) => {
        const dateKey = record.attendance_date;
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(record);
        return acc;
    }, {});

    // Convert the object to a sorted array (latest dates first)
    const sortedAttendance = Object.entries(groupedAttendance).sort(
        (a, b) => new Date(b[0]) - new Date(a[0])
    );

    return (
        <div >
            <Row className="mt-2">
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Card className="border">
                        <Card.Header className="py-3 px-5" style={{ borderBottom: '1px solid lightgray', fontSize: '13px' }}>Summary</Card.Header>
                        <Card.Body className="py-3">
                            <Row>
                                <Col lg={4} md={4} sm={4} xs={4}>
                                    <h6 style={{ color: 'gray' }}>Entries</h6>
                                    <text style={{ fontSize: '13px' }}>{attendanceHistory?.length}</text>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={4}>
                                    <h6 style={{ color: 'gray' }}>Hours</h6>
                                    <text style={{ fontSize: '13px' }}> {hours > 0
                                        ? `${hours} hr ${minutes} min`
                                        : `${totalMinutes} min`
                                    }</text>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={4}>
                                    <h6 style={{ color: 'gray' }}>Avg Hours</h6>
                                    <text style={{ fontSize: '13px' }}>{averageHours}</text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={6} sm={12} xs={12} className="mt-5">
                    {sortedAttendance.length > 0 &&
                        sortedAttendance.map(([date, records]) => (
                            <Card className="border mb-5" key={date}>
                                <Card.Header className="py-3 px-5" style={{ borderBottom: '1px solid lightgray', fontSize: '13px' }}>
                                    {formateDate(date)}
                                </Card.Header>
                                <Card.Body className="py-3">
                                    {records.map((data, index) => (
                                        <>
                                            <Row key={index} className="">
                                                <Col lg={12} md={12} sm={12} xs={12} className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-start">
                                                        <div><UilSignOutAlt size="20" color="#81C784" /></div>
                                                        <div className="d-flex flex-column mx-2">
                                                            <span style={{ fontSize: '13px', color: 'gray' }}>Check In</span>
                                                            <span style={{ fontSize: '13px' }}>
                                                                {data?.time_in
                                                                    ? new Date(`1970-01-01T${data?.time_in}`).toLocaleString('en-US', {
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                        hour12: true
                                                                    })
                                                                    : '--'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-start">
                                                        <div><UilSignOutAlt size="20" color="#E57373" /></div>
                                                        <div className="d-flex flex-column mx-2">
                                                            <span style={{ fontSize: '13px', color: 'gray' }}>Check Out</span>
                                                            <span style={{ fontSize: '13px' }}>
                                                                {data?.time_out
                                                                    ? new Date(`1970-01-01T${data?.time_out}`).toLocaleString('en-US', {
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                        hour12: true
                                                                    })
                                                                    : '--'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <span style={{ fontSize: '13px' }}>{calculateDuration(data?.time_in, data?.time_out)}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {index !== records.length - 1 && <hr />}
                                        </>
                                    ))}
                                </Card.Body>
                            </Card>
                        ))}
                </Col>
            </Row>

        </div>
    )
}

export default Attendance;