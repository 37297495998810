import React from "react";
import { Col, Row, Card  } from "react-bootstrap";
import {  UilAngleRight } from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import GlobalCurrency from "../../shared/helper/GlobalCurrency";
import { useNavigate } from "react-router-dom";

const TotalDue = () => {
  const navigate = useNavigate();
  const { getSubscriptionData, voucher } = useSelector(
    (state) => state.subscription
  );
  const { party } = useSelector((state) => state.auth);
  const filterdata =
    voucher &&
    voucher.length > 0 &&
    voucher.filter((value) => value?.party_id === party?.party_id);
  const dueSubscriptions = getSubscriptionData.filter(
    (subscription) => subscription.paid_amount !== subscription.grand_total
  );
  const totalDue = dueSubscriptions.reduce(
    (sum, subscription) => sum + subscription?.grand_total - Number(subscription?.paid_amount),
    0
  );

  const handleNavigate = () => {
    navigate("/subscription/invoices", { state: "unpaid" });
  }
  return (
    <Card
      className="shadow-xs"
      style={{
        backgroundColor: "white",
        //boxShadow: "0 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
        border: "1px solid #dee2e6",
      }}
      onClick={handleNavigate}
    >
      <Card.Body className="py-3 px-2">
        <Row>
          <Col lg={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between">
              {/* <UilTransaction size="16" color="purple" /> */}
              <text className="mx-2 bold" style={{ fontSize: "10px",color:"purple" }}>
                Total Due
              </text>
               <UilAngleRight size="20" color="gray" />
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between">
              <text className="mx-2 mb-0 " style={{ fontSize: "15px", }}>
                <GlobalCurrency price={totalDue || 0} />
              </text>
              {/* <UilAngleRight size="20" color="gray" /> */}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TotalDue;
