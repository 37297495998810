import {
  Row,
  Col,
  Button,
  Container,
  InputGroup,
  FormControl,
  Stack,
  Form,
  Tabs,
  Tab,
} from "react-bootstrap";
import Header from "../shared/Header";
import Layout from "../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import SubscriptionDetailsTable from "./components/subScriptionDetailsTable";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addExpires, getPrevious } from "../shared/helper/dateConvertion";
import BillingDetails from "./components/BillingDetails";
import TotalAmount from "./components/TotalAmount";
import Addon from "./components/Addon";
import NextBillDate from "./components/NextBillDate";
import BillFrequency from "./components/BillFrequency";
import InvoicesTable from "./components/InvoicesDetailsTable";
import MobileMembershipHighlights from "./mobileScreen/MobileMembershipHighlights";
import MembershipCard from "./components/MembershipCard";
import Attendance from "./mobileScreen/components/Attendance";

const SubscriptionDetails = () => {
  const { state } = useLocation();


  const addMonths = addExpires(state?.start_date, state?.expires_after);
  //

  const perviousDate = getPrevious(state?.start_date, state?.expires_after);
  //

  const totalAmount =
    state?.product?.sale_price * state?.subscription_items[0]?.quantity;

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);
  const [showInvoices, setShowInvoices] = useState(false);
  useEffect(() => {
    //  //
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleInvoice = () => {
    navigate("/subscription/invoice", { state: [state] });
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleInvoiceLabelClick = () => {
    setShowInvoices(true);
  };
  const handleSubscriptionLabelClick = () => {
    setShowInvoices(false);
  };

  const [activeTab, setActiveTab] = useState("overview");

  return (
    <Layout>
      {/* <SubbscriptionDetaislHeader data={state} /> */}
      <Header
        title={"Manage Membership"}
      />

      <Container fluid className="px-2 py-2" 
      // style={{
      //   height: "calc(100vh - 80px)",
      //   overflowY: "hidden",
      //   overflowX: "hidden",
      // }}
      >
        {!isMobile ? <div>
          <Row className="mt-3">
            <Col md={12}>
              <FontAwesomeIcon onClick={handleGoBack}
                className="mx-2"
                icon={faArrowLeft}
                style={{ cursor: "pointer" }}
                fontSize={20}
              />

              {state?.status == "active" && <text className="mx-2 fs-3 fw-bold" >{state?.subscription_number} &nbsp; <span style={{ color: '#155724', }}>(Active)</span> </text>}
              {state?.status == "pending" && <text className="mx-2 fs-3 fw-bold" >{state?.subscription_number} &nbsp; <span style={{ color: '#0D47A1', }}>(Pending)</span> </text>}
              {state?.status == "inactive" && <text className="mx-2 fs-3 fw-bold" >{state?.subscription_number} &nbsp; <span style={{ color: '#721C24', }}>(Inactive)</span> </text>}
              {state?.status == "cancelled" && <text className="mx-2 fs-3 fw-bold" >{state?.subscription_number} &nbsp; <span style={{ color: '#ED4337', }}>(Cancelled)</span> </text>}
            </Col>
          </Row>


          <Row className="mt-5" >
            <Col lg={12}>
              <Tabs
                activeKey={activeTab}
                onSelect={(key) => {
                  setActiveTab(key)
                }}
                id="uncontrolled-tab-example"
                className="mb-3"
                variant="underline"
                style={{ borderBottom: '1px solid lightgray' }}
              >
                <Tab eventKey="overview" title="Overview" />
                <Tab eventKey="attendance" title="Attendance" />
                <Tab eventKey="invoice" title="Invoices" />
                <Tab eventKey="membershipCard" title="Membership Card" />
              </Tabs>
            </Col>
            <Col lg={12} style={{
              height: "calc(100vh - 200px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}>
              {activeTab === "overview" && <SubscriptionDetailsTable data={state} />}
              {activeTab === "attendance" && <Attendance data={state} />}
              {activeTab === "invoice" && <InvoicesTable data={state} />}
              {activeTab === "membershipCard" && <MembershipCard data={state} />}
            </Col>

          </Row>
        </div> :
          <div className="px-2 py-3" style={{ backgroundColor: 'white', height: "calc(100vh - 80px)", borderRadius: '5px' }}>
            <MobileMembershipHighlights state={state} />
          </div>
        }
      </Container>
    </Layout >
  );
};
export default SubscriptionDetails;
