import { useState } from "react";
import { Button } from "react-bootstrap";
import MembershipCardModal from "./MembershipCardModal";
import { getLocalizedDate } from "../../../shared/helper/dateUtils";
import GlobalCurrency from "../../../shared/helper/GlobalCurrency";



const Overview = ({ state }) => {
    const [show, setShow] = useState(false);
    

    const handleShow = () => {
        setShow(true);
    }
    return (
        <>
            <div style={{ height: '60vh' }}>
                {state?.subscription_items?.length > 0 &&
                    state?.subscription_items?.map((data, index) => {
                        return (
                            <div className="d-flex flex-column">

                                <div className="mb-2 d-flex justify-content-between align-items-center" >
                                    <div className="d-flex flex-column ">
                                        <text className="" style={{ fontSize: '13px', color: 'gray' }}>Package</text>
                                        <text style={{ fontSize: '13px', color: '' }}>{state?.product?.name}</text>
                                    </div>
                                    <div className="d-flex flex-column align-items-end">
                                        <text className="" style={{ fontSize: '13px', color: 'gray' }}>Created At</text>
                                        <text className="" style={{ fontSize: '13px', color: '' }}>{getLocalizedDate(state?.product?.created_at)}</text>
                                    </div>
                                </div>
                                <div className="mb-2 d-flex justify-content-between" >
                                    <div className="d-flex flex-column ">
                                        <text className="" style={{ fontSize: '13px', color: 'gray' }}>Name</text>
                                        <text style={{ fontSize: '12px', color: '' }}>{data?.product?.name}</text>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <text style={{ fontSize: '13px', color: 'gray' }}>Type</text>
                                        <text className="" style={{ fontSize: '13px', color: '' }}>{data?.type}</text>

                                    </div>
                                    <div className="d-flex flex-column">
                                        <text className="" style={{ fontSize: '13px', color: 'gray' }}>Price</text>
                                        <text className="" style={{ fontSize: '13px', color: '' }}><GlobalCurrency price={data?.price || 0} /></text>
                                    </div>
                                </div>
                                <hr />


                            </div>)
                    })}
            </div>
            {state?.status == "active" && <div className="d-flex justify-content-center">
                <Button variant="outline-primary" onClick={handleShow}>View Membership Card</Button>
            </div>}

            {show && <MembershipCardModal show={show} data={state} setShow={setShow} />}
        </>
    )
}

export default Overview;