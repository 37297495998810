import { Modal, Button } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const NotificationModal = (props) => {
    const navigate = useNavigate();

    const { getSubscriptionData, getSubscriptionStatus, voucher } = useSelector(
        (state) => state.subscription
    );
    const { show, handleCloseNotification, notification } = props

    const handleNavigateToInvoice = () => {
       // const subId = 10072;
       var subId;
       if(typeof notification['id'] == 'string'){
           subId = parseInt( notification['id'])
       }else{
        subId = notification['id']
       }

        const rowData =
            getSubscriptionData &&
            getSubscriptionData.length > 0 &&
            getSubscriptionData.filter(
                (value) => value.id === subId
            );
        handleCloseNotification()
        navigate("/subscription/invoice", { state: rowData });
    }

    return (<Modal show={show} onHide={handleCloseNotification}>
        <Modal.Header closeButton>
            <Modal.Title> Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>Payment to invoice</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseNotification}>
                Close
            </Button>
            <Button variant="primary" onClick={handleNavigateToInvoice}>
                Payment
            </Button>
        </Modal.Footer>
    </Modal>)
}


export default NotificationModal;