import React, { useEffect, useState } from "react";
import SideBar from "./sidebar/SideBar";
import "./sidebar.css";

const Layout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isMobile ? (<div
        style={{
          overflowY: "hidden",
        }}
      >
        <div className="d-flex col-lg-12">
          <div className="">
            <SideBar />
          </div>
          <div className="w-100">
            {" "}
            <div
              style={{
                overflowX: "hidden",
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>) : (<div
        style={{
          height: "100vh",
          overflowY: 'hidden',
          overflowX: "hidden",
          backgroundColor: '#ECEFF1'
        }}
      >{children}</div>)}
    </>
  );
};

export default Layout;
