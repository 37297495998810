import { Col, Row } from "react-bootstrap";
import { getLocalizedDate, getLocalizedDateWithoutTime } from "../../shared/helper/dateUtils";
import GlobalCurrency from "../../shared/helper/GlobalCurrency";




const MembshipListCard = ({ item, index, handleRowClick }) => {
     console.log("item", item)
    const getStatusColor = (status) => {
        const colors = {
            active: { bg: "#D4EDDA", text: "#155724" },
            pending: { bg: "#D9EFFF", text: "#0D47A1" },
            inactive: { bg: "#F8D7DA", text: "#721C24" },
            cancelled: { bg: "#F8D7DA", text: "#721C24" },
        };
        return colors[status] || { bg: "#E0E0E0", text: "#000" };
    };

    const getStatusStyles = (status) => {
        const statusStyles = {
            Paid: { backgroundColor: "#D4EDDA", color: "#155724" },
            Unpaid: { backgroundColor: "#F8D7DA", color: "#721C24" },
            "Partially Paid": { backgroundColor: "#FFECB3", color: "#FF8F00" },
        };

        return statusStyles[status] || { backgroundColor: "#E0E0E0", color: "#333" };
    };

    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);


    return (
        <>
            <div className="px-7" key={index} onClick={() => handleRowClick(item)}>
                <div className="d-flex justify-content-between align-items-center">
                    <text style={{ fontSize: "13px", }}>{item?.subscription_number}</text>
                    <text style={{ fontSize: "13px", fontWeight: 700, color: getStatusColor(item?.status).text, }}>{capitalizeFirstLetter(item?.status)}</text>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center mt-2">
                    <text style={{ fontSize: "13px", }}>
                        {item?.product?.name} -{" "}
                        {item?.subscription_vouchers?.[0]?.voucher?.voucher_items?.[0]
                            ?.product_name}
                    </text>
                    <text style={{ fontSize: "13px", }}><GlobalCurrency price={item?.grand_total} /></text>
                </div> */}
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="d-flex flex-column ">
                        <text style={{ fontSize: "13px", color: 'gray' }}>Next Due</text>
                        <text style={{ fontSize: "13px", }}>{getLocalizedDateWithoutTime(item?.bill_queue?.next_bill_date) || '-'}</text>
                    </div>
                    <div className="d-flex flex-column ">
                        <text style={{ fontSize: "13px", color: 'gray' }}>Last Paid</text>
                        <text style={{ fontSize: "13px", }}>{getLocalizedDate(item?.last_paid_date) || '-'}</text>
                    </div>
                    <div className="d-flex flex-column ">
                        <text style={{ fontSize: "13px", color: 'gray' }}>Amount</text>
                        <text style={{ fontSize: "13px", }}><GlobalCurrency price={item?.grand_total} /></text>
                    </div>
                </div>
                <hr />
            </div>
        </>
    )
}

export default MembshipListCard;