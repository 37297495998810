import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { UilAngleRight } from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ActiveSubscription = () => {
  const navigate = useNavigate()
  const { getSubscriptionData } = useSelector(
    (state) => state.subscription
  );
  const activeSubscriptions = getSubscriptionData.filter(subscription => subscription.is_active === 1);
  const activeSubscriptionCount = activeSubscriptions.length;
  //
  const handleClick = () => {
    navigate('/subscription', { state: "active" })
  }
  return (
    <Card
      className="shadow-xs cursor-pointer"
      style={{
        backgroundColor: "white",
        //boxShadow: "0 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
        border: "1px solid #dee2e6",
      }}
      onClick={handleClick}
    >
      <Card.Body className="py-3 px-2">
        <Row>
          <Col lg={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between">
              {/* <UilPlay size="16" color="rgb(97, 199, 235" /> */}
              <text className="mx-2 bold text-truncate" style={{ fontSize: "10px", color: "#34B7E5" }}>
                Active Memberships
              </text>
              <UilAngleRight size="20" color="gray" />
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between">
              <text className="mx-2 mb-0 " style={{ fontSize: "15px", }}>
                {activeSubscriptionCount}
              </text>

            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActiveSubscription;
