import { useEffect, useRef, useState } from "react";
import { Button, Card, Image, Modal } from "react-bootstrap"
import { placeOfSupplyList } from "../../../shared/components/PlaceOfSupply";
import QRCode from "react-qr-code";
import logo from '../../../../assets/images/fitPlusLogo.png';
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { UilPrint, UilImport, UilUser } from "@iconscout/react-unicons";
import LoadingSpinner from "../../../shared/helper/LoadingSpinner";


const MembershipCardModal = ({ show, data, setShow }) => {
    const { businessname, businessaddres, businessCity, businessState, businessPincode, tenantId } = useSelector((state) => state.auth);

    const [qrCodeItemData, setQrCodeItemData] = useState(null);
    const [loading, setLoading] = useState(false);
    const componentRef = useRef();

    useEffect(() => {
        const qrCodeData = {
            member_id: data?.member_id,
            subscription_id: data?.id,
            tenant_id: tenantId?.tenant_id
        };
        setQrCodeItemData(JSON.stringify(qrCodeData));
    }, [data]);

    const state = placeOfSupplyList.find((value) => value.value == businessState);


    const DownloadPdf = () => {
        setLoading(true)
        if (!componentRef.current) {
            console.error("Component reference is not set.");
            setLoading(false);
            return;
        }

        html2canvas(componentRef.current, {
            scale: 2,
            useCORS: true,
            allowTaint: true,
            logging: true,
        }).then((canvas) => {

            const imageData = canvas.toDataURL("image/png");


            const link = document.createElement("a");
            link.href = imageData;
            link.download = `memberCard_${Date.now()}.png`;
            link.click();

            if (window.FlutterImage) {
                window.FlutterImage.postMessage(imageData);
            } else {
                console.error("Flutter WebView bridge is not available.");
                setLoading(false);
            }
        })
            .catch(error => {
                console.error("Error generating image:", error);
                setLoading(false);
            });
    };


    window.onPDFDownloadSuccess = function (message) {

        if (message == "success") {
            setLoading(false)
        } else if (message == "failed") {
            setLoading(false)
        } else {
            setLoading(false)
        }
    };



    return (
        <>
            <Modal show={show} keyboard={true} onHide={() => setShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Membership Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex  justify-content-center align-items-center" ref={componentRef}>
                        <Card className="shadow-sm border" style={{ width: "22rem" }}>
                            {/* <Card.Header className="d-flex justify-content-center align-items-center py-2" style={{ borderBottom: '1px solid lightgray' }}>
                            <Card.Title >Membership Card</Card.Title>
                        </Card.Header> */}
                            <Card.Body className="py-3">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div style={{ border: '1px solid gray', height: '10px', width: '50px', borderRadius: '10px' }}>

                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-5">
                                    <Image src={logo} height={50} width={80} alt="Your Logo" />
                                </div>

                                <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                                    <h5>{data?.subscription_number}</h5>
                                </div>
                                <div className="d-flex justify-content-center mt-5">
                                    <QRCode
                                        value={qrCodeItemData || ""}
                                        size={130}
                                        level={"H"}
                                        includeMargin={true}
                                    />
                                </div>
                                <hr />
                                <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
                                    <h4>{businessname}</h4>
                                    <text style={{ fontSize: '14px' }}>{businessaddres}</text>
                                    <text style={{ fontSize: '14px' }}>{businessCity},{state.label} - {businessPincode}</text>
                                </div>

                            </Card.Body>
                        </Card>
                    </div>

                    <div className="d-flex justify-content-center mt-5">
                        {!loading && <Button variant="outline-primary" className="py-1" onClick={DownloadPdf}>
                            <UilImport className="mx-2 mb-1" size="16" />
                            Download
                        </Button>}
                        {/* <Button variant="outline-secondary mx-4" className="py-1" onClick={handlePrint}>Print</Button> */}
                    </div>
                    {loading && <LoadingSpinner />}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MembershipCardModal;  