import { Col, Row } from "react-bootstrap";
import GlobalCurrency from "../../shared/helper/GlobalCurrency";
const PaidDetails = ({ data }) => {


  return (
    <>
      <Row className="py-2 ">
        <Col xs={6}>
          <text className="mb-0" style={{ fontSize: "13px" }}>
            Taxable Value
          </text>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <text className="mb-0" style={{ fontSize: "13px" }}>
            <GlobalCurrency price={data?.voucher?.taxable_value || '0.00'} />
          </text>
        </Col>
      </Row>
      <Row className="py-2">
        <Col xs={6}>
          <text className="mb-0" style={{ fontSize: "13px" }}>
            CGST
          </text>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <text className="mb-0" style={{ fontSize: "13px" }}>
            <GlobalCurrency price={data?.voucher?.cgst_total || 0} />
          </text>
        </Col>
      </Row>
      <Row className="py-2 ">
        <Col xs={6}>
          <text className="mb-0" style={{ fontSize: "13px" }}>
            SGST
          </text>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <text className="mb-0" style={{ fontSize: "13px" }}>
            <GlobalCurrency price={data?.voucher?.sgst_total || 0} />
          </text>
        </Col>
      </Row>
      <Row className="py-2 ">
        <Col xs={6}>
          <text className="mb-0" style={{ fontSize: "13px" }}>
            Tax
          </text>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <text className="mb-0" style={{ fontSize: "13px" }}>
            <GlobalCurrency price={data?.voucher?.tax_total || 0} />
          </text>
        </Col>
      </Row>
      <Row className="py-2 ">
        <Col xs={6}>
          <text className="mb-0" style={{ color: "green", fontSize: "16px", fontWeight: 500 }}>
            Total
          </text>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <text className="mb-0" style={{ fontSize: "13px" }}>
            <GlobalCurrency price={data?.voucher?.grand_total || 0} />
          </text>
        </Col>
      </Row>
    </>
  );
};

export default PaidDetails;
