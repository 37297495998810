export const getLocalizedDate = (isoDateString, locale = 'en-IN') => {
    try {
        if (!isoDateString) {
            return '';
        }
        const date = new Date(isoDateString);
        return date.toLocaleString(locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            //second: '2-digit',
            hour12: true
        });
    } catch (error) {
        console.error("Invalid date string:", error);
        return null;
    }
};

export const getLocalizedDateWithoutTime = (isoDateString, locale = 'en-IN') => {
    try {
        if (!isoDateString) {
            return '';
        }
        const date = new Date(isoDateString);
        return date.toLocaleString(locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            //second: '2-digit',
            hour12: true
        });
    } catch (error) {
        console.error("Invalid date string:", error);
        return null;
    }
};

