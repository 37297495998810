import { Col, Table } from "react-bootstrap";
import GlobalCurrency from "../../../shared/helper/GlobalCurrency";



const BottomPortion = ({ data }) => {
    return (
        <>
            <Table responsive>
                <thead>
                    <tr
                        style={{

                            fontSize: "13px",
                            textAlign: "center",
                            fontWeight: 500,
                        }}
                    >
                        <th
                            rowSpan={2}
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            HSN/SAC
                        </th>
                        <th
                            rowSpan={2}
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            TAXABLE VALUE
                        </th>
                        <th
                            colSpan={2}
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            CGST
                        </th>
                        <th
                            colSpan={2}
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                        >
                            SGST
                        </th>
                        <th
                            rowSpan={2}
                            style={{
                                color: "#4682B4",
                            }}
                        >
                            TOTAL TAX AMOUNT
                        </th>
                    </tr>
                    <tr
                        style={{
                            fontSize: "11px",
                            textAlign: "center",
                            fontWeight: 500,
                        }}
                    >
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                            className=""
                        >
                            RATE
                        </th>
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                            className=""
                        >
                            AMOUNT
                        </th>
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                            className=""
                        >
                            RATE
                        </th>
                        <th
                            style={{
                                color: "#4682B4",
                                borderRight: "1px solid lightgray",
                            }}
                            className=""
                        >
                            AMOUNT
                        </th>
                    </tr>
                </thead>
                {data &&
                    data?.voucher?.voucher_items &&
                    data?.voucher?.voucher_items.length > 0 &&
                    data?.voucher?.voucher_items.map((item, index) => {
                        return (
                            <tbody>
                                <tr
                                    key={index}
                                    style={{ fontSize: "13px", textAlign: "center",borderBottom:'1px solid lightgray' }}
                                >
                                    <td
                                        style={{
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        {item?.hsn ? item?.hsn : '-'}
                                    </td>
                                    <td
                                        style={{
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        <GlobalCurrency price={item?.taxable_value || 0} />
                                    </td>
                                    <td
                                        style={{
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        {item?.tax_rate
                                            ? `${item?.tax_rate / 2
                                            }%`
                                            : "N/A"}
                                    </td>
                                    <td
                                        style={{
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        <GlobalCurrency price={item?.cgst || 0} />
                                    </td>
                                    <td
                                        style={{
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        {item?.tax_rate
                                            ? `${item?.tax_rate / 2
                                            }%`
                                            : "N/A"}
                                    </td>
                                    <td
                                        style={{
                                            borderRight: "1px solid lightgray",
                                        }}
                                    >
                                        <GlobalCurrency price={item?.sgst || 0} />

                                    </td>
                                    <td><GlobalCurrency price={item?.tax_total || 0} /></td>
                                </tr>
                            </tbody>
                        );
                    })}
            </Table>

            <div className="d-flex " style={{ borderTop: '1px solid lightgray' }}>
                <Col className="px-2 py-2" xs={6}>
                    <text
                        className="mb-0"
                        style={{ fontSize: "13px", fontWeight: 600 }}
                    >
                        Terms And Condtions
                    </text>
                    <br />
                    <text className="mb-0" style={{ fontSize: "12px" }}>
                        Goods once sold not return back or exchange
                    </text>
                </Col>
                <Col
                    className="px-2 py-2"
                    xs={6}
                    style={{ borderLeft: "1px solid lightgray" }}
                >
                    <text
                        className="mb-0"
                        style={{ fontSize: "13px", fontWeight: 600 }}
                    >
                        AUTHORIZED SIGNATORY FOR
                    </text>
                    <br />
                    <div className="border mb-2" style={{ height: "50px" }}></div>
                </Col>
            </div>
        </>
    )
}

export default BottomPortion;