import React from "react";
import { Col, Row, Card,  } from "react-bootstrap";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const NextDueDate = () => {
  const { getSubscriptionData } = useSelector(
    (state) => state.subscription
  );

  const nearestDate = useMemo(() => {
    if (!getSubscriptionData || getSubscriptionData.length === 0) return null;

    const today = new Date();

    const parseDate = (dateString) => {
      const date = new Date(dateString);
      return isNaN(date) ? null : date;
    };

    const datesWithDifference = getSubscriptionData
      .map((dateString) => {
        const date = parseDate(dateString?.bill_queue?.next_bill_date);
        return date ? { date, difference: Math.abs(date - today) } : null;
      })
      .filter((dateObj) => dateObj !== null);

    if (datesWithDifference.length === 0) return null;

    datesWithDifference.sort((a, b) => a.difference - b.difference);

    return datesWithDifference[0].date;
  }, [getSubscriptionData]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formattedNearestDate = nearestDate
    ? formatDate(nearestDate)
    : "No valid date found";
  //
  return (
    <Card
      className="shadow-xs"
      style={{
        backgroundColor: "white",
        //boxShadow: "0 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
        border: "1px solid #dee2e6",
      }}
    >
      <Card.Body className="py-3 px-2">
        <Row>
          <Col lg={12} sm={12} xs={12}>
            <div className="d-flex">
            {/* <UilCalender size="16" color="#FFA500" /> */}
              <text className="mx-2 bold" style={{ fontSize: "10px",color:"#E59400" }}>
              Next Due Date
              </text>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between">
              <text className="mx-2 mb-0 " style={{ fontSize: "15px", }}>
                {formattedNearestDate}
              </text>
              {/* <UilAngleRight size="20" color="gray" /> */}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NextDueDate;
