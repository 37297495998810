import { useEffect, useState } from "react";
import Header from "../shared/Header";
import Layout from "../shared/components/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPaymentsWithPartyId
} from "../subscription/store/subscriptionSlice";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { UilFilePlusAlt } from "@iconscout/react-unicons";
import { Badge, Card, Col, Container, FormControl, Row } from "react-bootstrap";

const PaymentIndex = (props) => {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
//
const { payment } = useSelector((state) => state.subscription);
//
   const { party,accessToken,tenantId } = useSelector((state) => state.auth);

   useEffect(() => {
    let partyId = party?.party_id
    dispatch(getAllPaymentsWithPartyId({partyId,accessToken,tenantId}));
   }, []);

   const filterdata =
   payment &&
   payment.length > 0 &&
   payment.filter((value) => value?.party_id === party?.party_id);
 //
 const [filteredData, setFilteredData] = useState(
   filterdata ? filterdata : []
 );  const [searchString, setSearchString] = useState("");
  //

  useEffect(() => {
    if (searchString.length == 0) {
      setFilteredData(payment);
    } else {
      setFilteredData(filteredData);
    }
  }, [searchString]);

  const handleSearch = (searchParam) => {
    const lowerCaseSearchParam = searchParam.toLowerCase();

    setSearchString(searchParam);
    const filtered = payment.filter(
      (datum) =>
        (datum?.payment_number &&
          datum?.payment_number
            .toLowerCase()
            .includes(lowerCaseSearchParam)) 
);
  
    setFilteredData(filtered);
    //
  };

  const columns = [
    {
      id: "id",
      name: "#",
      selector: (row) => row?.id,
     sortable: true,
    },
    {
      id: "party_id",
      name: "Party Id",
      selector: (row) => row?.party_id,
      sortable: true,
    },
    {
      id: "payment_number",
      name: "Payment Number",
      selector: (row) => row?.payment_number,
      sortable: true,
    },
    {
      id: "type",
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
    },
    {
      id: "amount",
      name: "Amount",
      selector: (row) => row?.amount,
      sortable: true,
    }
  ];

  return (
    <Layout>
      <Header title={"Payments"} />
      <Container className="p-2" fluid style={{ backgroundColor: "#f1f3f9" }}>
        <Row>
          <Col xs={12} md={8} lg={8}>
          {filteredData &&
              filterdata.length > 0 &&
              filteredData.map((data, index) => {
                const status =
                data?.mode === "cash"
                  ? "cash"
                  : data?.mode === "card"
                  ? "card"
                  : "UPI";
                  //
                return (
<Card
  style={{ cursor: 'pointer' }}
  className="mb-2"
  key={index}
>

  <Card.Body>
    <div className="d-flex justify-content-between align-items-center">
      <Card.Title
        style={{
          fontSize: isMobile ? "12px" : "13px",
          fontWeight: 500,
        }}
      >
    <div>{data?.payment_number}</div>
    <div> </div>
    <div>{new Date(data?.created_at).toLocaleDateString('en-IN')} {new Date(data?.created_at).toLocaleTimeString()}</div>  
    </Card.Title>
      <Card.Title
        style={{
          fontSize: isMobile ? "12px" : "13px",
          fontWeight: 500,
        }}
      >
<Col>₹ {parseFloat(data?.amount).toFixed(2)}</Col> 
     </Card.Title>
    </div>
    <div className="d-flex justify-content-between align-items-center">
      <div
        className="d-flex flex-column justify-content-start"
        style={{
          marginLeft: "2px",
          fontSize: isMobile ? "10px" : "12px",
          fontWeight: 400,
        }}
      >
      </div>
      <div
        style={{
          fontSize: isMobile ? "10px" : "12px",
          fontWeight: 400,
        }}
      >
 <Badge
  bg={
    status === "cash"
      ? "success"
      : status === "card"
      ? "warning"
      : "primary"
  }
  style={{
    padding: "5px",
    borderRadius: "5px",
    color:
      status === "cash" || status === "card" 
        ? "white"
        : "white",
  }}
>
{ status === "cash" ? "Cash" : status === "card" ? "Card" : "UPI"}
</Badge>
      </div>
    </div>
  </Card.Body>
</Card>
                );
              })}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default PaymentIndex;
