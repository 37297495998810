import axios from 'axios';



//axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//

export const axiosRequest = async (params) => {
  //
  try {
    params.headers = { ...params.headers };
    const result = await axios.request(params);
    //
    return result?.data;
  } catch (error) {
    
    const errorMessage =
      error.response?.data?.message || error.response?.data?.error;

    
    throw errorMessage;
  }
};