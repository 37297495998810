import { getLocalizedDate } from "../../shared/helper/dateUtils";
import GlobalCurrency from "../../shared/helper/GlobalCurrency";



const InvoiceListCard = ({ item, index, handleRowClick }) => {
    console.log("item status",item)
    const getStatus = (item) => {
       
        if (item?.paid_amount === item?.grand_total) return "Paid";
        if (item?.paid_amount > 0 && item?.paid_amount < item?.grand_total)
            return "Partially Paid";
        return "Unpaid";
    };
    const status = getStatus(item);

    const getStatusStyles = (status) => {
        const statusStyles = {
            Paid: { backgroundColor: "#D4EDDA", color: "#155724" },
            Unpaid: { backgroundColor: "#F8D7DA", color: "#721C24" },
            "Partially Paid": { backgroundColor: "#FFECB3", color: "#FF8F00" },
        };

        return statusStyles[status] || { backgroundColor: "#E0E0E0", color: "#333" };
    };

    return (
        <>
            <div className=" px-7" key={index} onClick={() => handleRowClick(item)}>
                <div className="d-flex justify-content-between align-items-center">
                    <text style={{ fontSize: '13px' }}>{item?.voucher_number}</text>
                    <text style={{ fontSize: '13px' }}>{getLocalizedDate(item?.created_at)}</text>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <text style={{ fontSize: '13px' }}>
                        {item?.voucher_items[0]?.product_name} -{" "}
                        {item?.voucher_items[0]?.product_code}
                    </text>
                    <text style={{ fontSize: '13px' }}><GlobalCurrency price={item?.grand_total} /></text>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="d-flex flex-column justify-content-start">
                        <text style={{ color: "gray", fontSize: '13px' }}>Paid Amount</text>
                        <text className="mt-1" style={{ fontSize: '13px' }}><GlobalCurrency price={item?.paid_amount} /></text>
                    </div>
                    <div className="d-flex flex-column">
                      
                        {status && (
                                <div
                                  className="px-2"
                                  style={{
                                    ...getStatusStyles(status),
                                    fontSize: "13px",
                                    borderRadius: "5px",
                                    fontWeight:700
                                  }}
                                >
                                  {status}
                                </div>
                              )}
                    </div>
                </div>
                <hr />
            </div>
            
        </>
    )
}

export default InvoiceListCard;