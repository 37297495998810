import { Col, Row } from "react-bootstrap";
import logo from '../../../../assets/images/fitPlusLogo.png'
import { useSelector } from "react-redux";
import { formateDate } from "../../../shared/helper/dateConvertion";
import { placeOfSupplyList } from "../../../shared/components/PlaceOfSupply";

const TopPortion = ({ data, party }) => {
    const { businessname, businessaddres, businessCity, businessState, businessPincode } = useSelector((state) => state.auth);

    const state = placeOfSupplyList.find((value) => value.value == businessState);

    const partyState = placeOfSupplyList.find((value) => value.value == party?.party_addresses[0]?.state)


    return (
        <>
            <div
                className="d-flex  px-2"
                style={{
                    // borderTop: "1px solid lightgray",
                    borderBottom: "1px solid lightgray",
                }}
            >
                <Col xs={6} className="p-2">
                    <img
                        src={logo}
                        width={120}
                        height={60}
                        alt="Logo"
                        className=" mt-6"
                    />
                </Col>
                <Col
                    xs={6}
                    className="p-2"
                    style={{ borderLeft: "1px solid lightgray" }}
                >
                    <div class="d-flex justify-content-between col-12">
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <text
                                className="mb-0"
                                style={{ fontSize: "14px", fontWeight: 500 }}
                            >
                                {businessname}
                            </text>
                            <text
                                className="mb-0"
                                style={{ fontSize: "12px", fontWeight: 400 }}
                            >
                                {businessaddres}
                            </text>
                            <text
                                className="mb-0"
                                style={{ fontSize: "12px", fontWeight: 400 }}
                            >
                                {businessCity}, {state.label} - {businessPincode}
                            </text>
                        </div>
                        <div>
                            <text
                                className="mb-0 form-label"
                                style={{ fontSize: "14px", fontWeight: 500 }} >
                                {"Subscription No"}
                            </text>
                            <br />
                            <text style={{ fontSize: "12px" }}>
                                {data?.subscription?.subscription_number}
                            </text> </div>
                    </div>
                </Col>
            </div>

            <Row>
                <Col lg={6}>
                    <div className="px-3 py-2 d-flex flex-column"
                        style={{}}>
                        <text className="mb-0">{"BILL TO"}</text>
                        <text className="mb-0" style={{ fontSize: "12px" }}>
                            {" "}
                            {party?.name}
                        </text>
                        {party?.party_addresses && party?.party_addresses.length > 0 && <text className="mb-0" style={{ fontSize: "12px" }}>
                            {" "}
                            {party?.party_addresses[0]?.address},
                        </text>}
                        {party?.party_addresses && party?.party_addresses.length > 0 && <text className="mb-0" style={{ fontSize: "12px" }}>
                            {" "}
                            {party?.party_addresses[0]?.city}, {partyState?.label} - {party?.party_addresses[0]?.pincode}
                        </text>}
                        <text className="mb-0" style={{ fontSize: "12px" }}>
                            {" "}
                          <span style={{ fontSize: "12px",fontWeight:500 }}>Phone :</span>  {party?.phone_number}
                        </text>
                        <text className="mb-0" style={{ fontSize: "12px" }}>
                            {" "}
                            {party?.gstin}
                        </text>
                        <text className="mb-0" style={{ fontSize: "12px" }}>
                            {party?.pan}
                        </text>
                    </div></Col>
                <Col lg={6} style={{ borderLeft: "1px solid lightgray" }}>
                    <div className="d-flex justify-content-between align-items-center  py-2 px-3">
                        <div className="">
                            <text
                                className="mb-0"
                                style={{ fontSize: "13px", fontWeight: 500 }}
                            >
                                {"Invoice No"}
                            </text>
                            <br />
                            <text className="mb-0" style={{ fontSize: "12px" }}>
                                {data?.voucher?.voucher_number}
                            </text>
                        </div>
                        <div className="">
                            <text
                                className="mb-0"
                                style={{ fontSize: "13px", fontWeight: 500 }}
                            >
                                {"Invoice Date"}
                            </text>
                            <br />
                            <text className="mb-0" style={{ fontSize: "12px" }}>
                                {formateDate(data?.subscription?.start_date)}
                            </text>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}


export default TopPortion;  