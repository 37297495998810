import { Badge, Button, Card, Col, ListGroup, Row, Tab, Tabs } from "react-bootstrap"
import Addon from "../components/Addon"
import BillFrequency from "../components/BillFrequency"
import NextBillDate from "../components/NextBillDate"
import TotalAmount from "../components/TotalAmount"
import { UilCreditCard, UilPuzzlePiece, UilCalender, UilRepeat } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { taxList } from "../../shared/helper/taxConfig"
import Overview from "./components/Overview"
import Attendance from "./components/Attendance"
import MobileInvoiceList from "./components/MobileInvoice"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSubscriptionAttendance } from "../store/subscriptionSlice"
import MembershipCard from "../components/MembershipCard"



const MobileMembershipHighlights = ({ state }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { party, accessToken, tenantId } = useSelector((state) => state.auth);

     

    const getStatus = (data) => {
        
        const paidAmount = state?.subscription_vouchers[0]?.voucher?.paid_amount || 0;
        const grandTotal = state?.grand_total || 0;
        //
        if (paidAmount == grandTotal) return "Paid";
        if (paidAmount > 0 && paidAmount < grandTotal) return "Partially Paid";
        return "Unpaid";
    };
    const status = getStatus(state);


    const TaxLabel = taxList.find((value) => value.code == state?.subscription_items[0]?.tax_code);
    const AddonTaxLabel = taxList.find((value) => value.code == state?.subscription_items[1]?.tax_code);

    const handleInvoice = () => {
        navigate("/subscription/invoice", { state: [state] });
    };

    const handleGoBack = () => {
        navigate(-1);
    };


    useEffect(() => {
        const args = {
            tenant_id: tenantId,
            accessToken: accessToken,
            id: state?.id
        }
        dispatch(getSubscriptionAttendance(args))
    }, [])


    

    return (
        <>
            <Row className="mt-3 ">
                <Col xs={12} md={6}>
                    <div className="d-flex justify-content-start align-items-center">
                        <FontAwesomeIcon onClick={handleGoBack}
                            className="mx-2"
                            icon={faArrowLeft}
                            style={{ cursor: "pointer" }}
                            fontSize={20}
                        />
                        {state?.status == "active" && <text className="mx-2 fs-3 bold" >{state?.subscription_number} &nbsp; <span style={{ color: '#155724', }}>Active</span> </text>}
                        {state?.status == "pending" && <text className="mx-2 fs-3 bold" >{state?.subscription_number} &nbsp; <span style={{ color: '#0D47A1', }}>Pending</span> </text>}
                        {state?.status == "inactive" && <text className="mx-2 fs-3 bold" >{state?.subscription_number} &nbsp; <span style={{ color: '#721C24', }}>Inactive</span> </text>}
                        {state?.status == "cancelled" && <text className="mx-2 fs-3 bold" >{state?.subscription_number} &nbsp; <span style={{ color: '#ED4337', }}>Cancelled</span> </text>}

                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Tabs
                        defaultActiveKey="membership"
                        id="uncontrolled-tab-example"
                        className="mb-3 custom-tabs"
                        variant="underline"
                        style={{ borderBottom: '1px solid lightgray' }}
                    >
                        <Tab eventKey="membership" title="Overview" className="px-2">
                            <div style={{ height: 'calc(100vh - 200px)', overflowY: 'auto',overflowX:'hidden' }}>
                                <Overview state={state} />
                            </div>
                        </Tab>
                        <Tab eventKey="attendance" title="Attendance" className="px-2">
                            <div style={{ height: 'calc(100vh - 200px)', overflowY: 'auto',overflowX:'hidden' }}>
                                <Attendance state={state} />
                            </div>
                        </Tab>
                        <Tab eventKey="invoice" title="Invoices" className="px-2">
                            <div style={{ height: 'calc(100vh - 200px)', overflowY: 'auto',overflowX:'hidden' }}>
                                <MobileInvoiceList state={state} />
                            </div>
                        </Tab>
                        {/* <Tab eventKey="membershipCard" title="Membership Card">
                <div style={{ height: 'calc(100vh - 150px)', overflowY: 'auto' }}>
                    <MembershipCard data={state} />
                </div>
            </Tab> */}
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}

export default MobileMembershipHighlights;